import {
  Autocomplete,
  Avatar,
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { UploadIcon } from '@primer/octicons-react';
import { FormikProps } from 'formik';
import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { OnSiteTypes } from '../../../../shared/constants/OnsiteDeployment';
import { OrganisationListItem } from '../../../../shared/types/organisation/OrganisationListItem';
import { useGetConfig } from '../../../../utilities/hooks/useGetConfig';
import { useGetOrganisations } from '../../../../utilities/hooks/useGetOrganisations';
import { ISlotTime } from '../../../../utilities/interfaces/Date';
import { useGetOnsiteLocations } from '../hooks/useGetOnsiteLocations';
import {
  IAddOnsiteDeploymentInputs,
  OnsiteTimeIntervals,
} from './OnsiteSessionDetails';

const OnsiteBasicConfiguration = ({
  formik,
  setShowImageUploadDialog,
  isEditDisabled,
}: {
  formik: FormikProps<IAddOnsiteDeploymentInputs>;
  setShowImageUploadDialog: Dispatch<SetStateAction<boolean>>;
  isEditDisabled: boolean;
}) => {
  const { data: configData } = useGetConfig();

  const [opsSite, setOpsSite] = useState('');
  const { data: organisations } = useGetOrganisations();
  const { data: locations } = useGetOnsiteLocations();

  const [startTimeIndex, setStartTimeIndex] = useState<number>(0);

  const durationTillSecondDay = useMemo(() => {
    const total_slots = 96;
    const diff = total_slots - +formik?.values?.fromTime;
    const isEightHour = formik?.values?.onSiteType === OnSiteTypes.EIGHT_HOUR;

    return diff <= (isEightHour ? 32 : 16) ? true : false;
  }, [formik?.values?.onSiteType, formik?.values?.fromTime]);

  // sub-range of end time values, we don't want duplicate keys for empty option so we conditionally builds endTimeOptions array
  const endTimeOptions: ISlotTime[] = useMemo(() => {
    if (durationTillSecondDay) {
      return [...OnsiteTimeIntervals.slice(startTimeIndex + 32 - 128)];
    } else {
      return [...OnsiteTimeIntervals.slice(startTimeIndex)];
    }
  }, [startTimeIndex, durationTillSecondDay]);

  const selectedOrganisations = useMemo(() => {
    return organisations?.find(
      (item) => item?.id == formik?.values?.organisationId,
    );
  }, [organisations, formik?.values?.organisationId]);

  const sessionImageLink = useMemo(() => {
    return formik?.values?.cardImageUrl
      ? formik?.values?.cardImageUrl.startsWith('http')
        ? formik?.values?.cardImageUrl
        : configData?.urls.imageBase + formik?.values?.cardImageUrl
      : null;
  }, [formik?.values?.cardImageUrl]);

  useEffect(() => {
    if (formik?.values?.fromTime) {
      if (!formik?.values?.onSiteType) {
        formik.setFieldTouched('onSiteType', true);
        formik.setErrors({ onSiteType: 'Please select' });

        return;
      }
      setStartTimeIndex(
        Number(formik?.values?.fromTime) +
          Number(
            formik?.values?.onSiteType === OnSiteTypes.EIGHT_HOUR ? 8 : 4,
          ) *
            4,
      );
    }
  }, [formik?.values?.fromTime, formik?.values?.onSiteType]);

  const filteredAddresses = useMemo(() => {
    return locations?.filter((loc) => loc.city === opsSite);
  }, [locations, opsSite]);

  const selectedAddress = useMemo(() => {
    return locations?.find((loc) => loc.id === formik?.values?.locationId);
  }, [locations, formik?.values?.locationId]);

  useEffect(() => {
    if (selectedAddress?.city) {
      setOpsSite(selectedAddress?.city);
    }
  }, [selectedAddress]);

  return (
    <>
      <Grid item xs={6}>
        <InputLabel>Ops site</InputLabel>
        <Autocomplete
          disablePortal
          key="locations"
          options={Array.from(new Set(locations?.map((item) => item.city)))}
          sx={{ width: '75%' }}
          value={opsSite}
          onChange={(event, newValue) => {
            if (newValue) {
              setOpsSite(newValue);
              if (opsSite !== newValue) {
                formik.setFieldValue('locationId', undefined);
              }
            }
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="ex: Singapore" />
          )}
          size="small"
          disabled={isEditDisabled}
        />
      </Grid>
      <Grid item xs={6}>
        <InputLabel>Address of the Office</InputLabel>
        <Autocomplete
          disablePortal
          key={`address-${opsSite}-${selectedAddress?.id}`}
          value={selectedAddress}
          options={filteredAddresses ?? []}
          getOptionLabel={(option) => option?.address}
          sx={{ width: '75%' }}
          onChange={(event, newValue) => {
            formik.setFieldValue('locationId', newValue?.id);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Ex: TikTok Office, 5th Street , Bleh bleh"
              error={
                formik?.touched.locationId && Boolean(formik?.errors.locationId)
              }
              helperText={
                formik?.touched.locationId && formik?.errors.locationId
              }
            />
          )}
          size="small"
          disabled={isEditDisabled}
        />
      </Grid>
      <Grid item xs={6}>
        <InputLabel>Organisation</InputLabel>
        <Autocomplete
          disablePortal
          key={`org-id-${selectedOrganisations?.id}`}
          value={selectedOrganisations}
          options={(organisations ?? []) as OrganisationListItem[]}
          getOptionLabel={(option) => option?.name}
          sx={{ width: '75%' }}
          onChange={(event, newValue) => {
            formik.setFieldValue('organisationId', newValue?.id);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="ex: Dell T&S"
              error={
                formik?.touched.organisationId &&
                Boolean(formik?.errors.organisationId)
              }
              helperText={
                formik?.touched.organisationId && formik?.errors.organisationId
              }
            />
          )}
          size="small"
          disabled={isEditDisabled}
        />
      </Grid>
      <Grid item xs={6}>
        <InputLabel>Type of onsite</InputLabel>
        <FormControl>
          <RadioGroup
            row
            value={formik?.values?.onSiteType}
            onChange={(e) =>
              formik?.setFieldValue('onSiteType', e.target.value)
            }
          >
            <FormControlLabel
              value={OnSiteTypes.EIGHT_HOUR}
              control={<Radio />}
              label="8 Hour"
              disabled={Boolean(
                (formik?.values?.onSiteType && formik?.values?.id) ||
                  isEditDisabled,
              )}
            />
            <FormControlLabel
              value={OnSiteTypes.FOUR_HOUR_NIGHT}
              control={<Radio />}
              label="4 Hour (Night)"
              disabled={Boolean(
                (formik?.values?.onSiteType && formik?.values?.id) ||
                  isEditDisabled,
              )}
            />
          </RadioGroup>
          {formik?.touched?.onSiteType &&
          Boolean(formik?.errors?.onSiteType) ? (
            <FormHelperText sx={{ color: 'error.main' }}>
              {formik?.touched?.onSiteType && formik?.errors?.onSiteType}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <InputLabel id="description">Card image</InputLabel>
        <Box display="flex">
          <TextField
            sx={{ width: '75%' }}
            name="cardImageUrl"
            onChange={formik.handleChange}
            id="main-image"
            value={formik?.values?.cardImageUrl ?? ''}
            placeholder="No main image"
            size="small"
            error={
              formik?.touched?.cardImageUrl &&
              Boolean(formik?.errors.cardImageUrl)
            }
            helperText={
              formik?.touched?.cardImageUrl && formik?.errors.cardImageUrl
            }
            disabled={isEditDisabled}
          />
          <Box display={'flex'} height={'2.5rem'}>
            <a
              rel="noreferrer"
              target={sessionImageLink ? '_blank' : '_self'}
              href={sessionImageLink ?? '#'}
            >
              <Avatar
                alt="Onsite Image"
                sx={{ width: 40, height: 40, marginLeft: 2 }}
                src={sessionImageLink ?? ''}
              />
            </a>
            <IconButton
              size="large"
              aria-label="Upload main image"
              onClick={() => setShowImageUploadDialog(true)}
              disabled={isEditDisabled}
            >
              <UploadIcon size={24} />
            </IconButton>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <InputLabel>
          Duration of the onsite <br />
          <Typography variant="subtitle2" fontSize={'14px'}>
            Enter the requested duration of the onsite deployment (8 or 4 hours)
            in the exact time slots in{' '}
            <span style={{ color: 'red' }}>UTC timezone.</span>
          </Typography>
        </InputLabel>
        <Grid container>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid item xs={6}>
              <Grid container columnSpacing={2}>
                <Grid item xs={12}>
                  <InputLabel>From</InputLabel>
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    openTo="day"
                    onChange={(value) => {
                      formik.setFieldValue(
                        'fromDate',
                        moment(value).toISOString(),
                      );
                      formik.setFieldValue('toTime', '');
                      formik.setFieldValue('toDate', null);
                    }}
                    key={'deployment-from-date'}
                    minDate={moment().toDate()}
                    value={moment(formik.values.fromDate)}
                    disabled={Boolean(formik?.values?.id || isEditDisabled)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={
                          formik?.touched?.fromDate &&
                          Boolean(formik?.errors.fromDate)
                        }
                        helperText={
                          formik?.touched?.fromDate && formik?.errors.fromDate
                        }
                        fullWidth
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    size="small"
                    label="From"
                    sx={{ width: '50%' }}
                    defaultValue={''}
                    value={formik?.values?.fromTime ?? ''}
                    onChange={(e) => {
                      formik.setFieldValue('fromTime', e.target.value);
                      formik.setFieldValue('toTime', '');
                      formik.setFieldValue('toDate', null);
                    }}
                    error={
                      formik?.touched?.fromTime &&
                      Boolean(formik?.errors.fromTime)
                    }
                    disabled={Boolean(formik?.values?.id || isEditDisabled)}
                  >
                    {OnsiteTimeIntervals?.slice(
                      0,
                      OnsiteTimeIntervals.length - 1,
                    )
                      ?.map(({ slot, time }) => ({
                        value: slot.toString(),
                        label: time,
                      }))
                      ?.map((item, idx) => {
                        return (
                          <MenuItem
                            key={`${idx}-${item.value}`}
                            value={item.value}
                          >
                            {item.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {formik?.touched?.fromTime &&
                  Boolean(formik?.errors?.fromTime) ? (
                    <FormHelperText sx={{ color: 'error.main' }}>
                      {formik?.touched?.fromTime && formik?.errors?.fromTime}
                    </FormHelperText>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container columnSpacing={2}>
                <Grid item xs={12}>
                  <InputLabel>To</InputLabel>
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    openTo="day"
                    onChange={(value) =>
                      formik.setFieldValue(
                        'toDate',
                        moment(value).toISOString(),
                      )
                    }
                    key={'deployment-to-date'}
                    minDate={moment(formik?.values?.fromDate)
                      .add(durationTillSecondDay ? 1 : 0, 'day')
                      .toDate()}
                    maxDate={moment(formik?.values?.fromDate)
                      .endOf('day')
                      .add(durationTillSecondDay ? 1 : 0, 'day')
                      .toDate()}
                    value={formik.values.toDate}
                    disabled={
                      !formik?.values?.fromTime ||
                      !formik?.values?.onSiteType ||
                      Boolean(formik?.values?.id || isEditDisabled)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        error={
                          formik?.touched?.toDate &&
                          Boolean(formik?.errors.toDate)
                        }
                        helperText={
                          formik?.touched?.toDate && formik?.errors.toDate
                        }
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    size="small"
                    label="To"
                    sx={{ width: '50%' }}
                    defaultValue={''}
                    value={formik.values.toTime}
                    disabled={
                      !formik?.values?.fromTime ||
                      !formik?.values?.onSiteType ||
                      Boolean(formik?.values?.id) ||
                      isEditDisabled
                    }
                    onChange={(e) =>
                      formik.setFieldValue('toTime', e.target.value)
                    }
                    error={
                      formik?.touched?.toTime && Boolean(formik?.errors.toTime)
                    }
                  >
                    {endTimeOptions
                      .map(({ slot, time }) => ({
                        value: slot.toString(),
                        label: time,
                      }))
                      ?.map((item, idx) => {
                        return (
                          <MenuItem
                            key={`${idx}-${item.value}`}
                            value={item.value}
                          >
                            {item.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {formik?.touched?.toTime &&
                  Boolean(formik?.errors?.toTime) ? (
                    <FormHelperText sx={{ color: 'error.main' }}>
                      {formik?.touched?.toTime && formik?.errors?.toTime}
                    </FormHelperText>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Grid>
      </Grid>
    </>
  );
};

export default OnsiteBasicConfiguration;
