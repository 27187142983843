import { DownloadIcon } from '@primer/octicons-react';
import StyledButton from '../../widgets/styledButton/StyledButton';
import { OrganisationServiceV2 } from '../../../shared/service/services_v2';
import { getUserCookie } from '../../../utilities/Api';
import toast from 'react-hot-toast';
import { useState } from 'react';
import moment from 'moment';
import { CircularProgress } from '@mui/material';

export const ExcelExportView = ({ orgId }: { orgId: number }) => {
  const [loading, setLoading] = useState(false);
  const handleDownload = async () => {
    try {
      setLoading(true);
      // Fetch the download URL from the server
      const fileUrl = await OrganisationServiceV2.exportMembers(+orgId);

      const response = await fetch(fileUrl, {
        method: 'GET',
        headers: {
          Authorization: `${getUserCookie()}`,
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      });

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `members_${orgId}_${moment().format('DD_MMM_YYYY')}.xlsx`,
      );

      document.body.appendChild(link);
      link.click();

      link.remove();
      window.URL.revokeObjectURL(url);
      toast.success('Members exported successfully.');
    } catch (error) {
      toast.error('Error while downloading the file:');
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledButton
      color="light"
      variant="ghost"
      startIcon={
        !loading ? (
          <DownloadIcon size={18} />
        ) : (
          <CircularProgress size={18} sx={{ color: '#8c90a6' }} />
        )
      }
      onClick={handleDownload}
      disabled={loading}
    >
      Export Members
    </StyledButton>
  );
};
