import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { UploadIcon } from '@primer/octicons-react';
import { AxiosError } from 'axios';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { MaterialIcons } from '../../../../assets/MaterialIcons';
import { AppServiceV2 } from '../../../../shared/service/services_v2';
import StyledButton from '../../../widgets/styledButton/StyledButton';
import FileDropZone from '../../FileDropZone/FileDropZone';
import SectionHeader from '../../SectionHeader/SectionHeader';
import { Accept } from 'react-dropzone';

const UploadFileView = ({
  onSuccess,
  accept = {},
}: {
  onSuccess?: (url: string) => void;
  accept?: Accept;
}) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [urls, setUrls] = useState<
    { fileName: string; location: string; cdnUrl: string }[]
  >([]);
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);

  // Perform the upload
  const handleUpload = async () => {
    let response = null;
    try {
      setLoading(true);
      response = await AppServiceV2.uploadImage(files);

      if (response && response.data.success) {
        setSuccess(true);
        toast.success('Files uploaded successfully!');
        if (onSuccess) {
          onSuccess(response.data.data[0].fileName);
        }
        setUrls([...urls, ...response.data.data]);
        setFiles([]);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(
        err.response?.status === 422
          ? `${err.response?.data.message} : ${err.response?.data?.errors[0]}`
          : 'An error occurred while uploading the files. Please check the files uploaded and try again.',
      );
    } finally {
      setLoading(false);
    }
  };

  const removeFromFileList = (name: string) => {
    setFiles(files.filter((file: File) => file.name !== name));
  };

  return (
    <>
      <SectionHeader
        title={'File Upload'}
        rightElement={
          <>
            <StyledButton
              onClick={handleUpload}
              disabled={!files?.length || loading}
              size="large"
              startIcon={
                !loading ? (
                  <UploadIcon size={18} />
                ) : (
                  <CircularProgress size={18} color="inherit" />
                )
              }
              sx={{
                width: '13rem',
                padding: '0.5rem 1.5rem',
                alignSelf: 'flex-start',
                fontSize: 18,
                margin: 0,
              }}
            >
              {loading ? 'Uploading' : 'Upload'}
            </StyledButton>
          </>
        }
      />

      <Divider sx={{ my: 3 }} />

      <FileDropZone
        files={files}
        setFiles={setFiles}
        multiple
        disabled={loading}
        accept={accept}
      />

      <Box mt={2}>
        {files.length > 0 ? (
          <>
            <Typography variant="h6" fontWeight="bold">
              Selected files ({files?.length})
              <StyledButton
                color="gray"
                variant="ghost"
                startIcon={MaterialIcons.Close}
                onClick={() => setFiles([])}
                sx={{ margin: 0 }}
              >
                Clear All
              </StyledButton>
            </Typography>
          </>
        ) : (
          <Typography variant="h6">No files selected</Typography>
        )}
        {files.length > 0 && (
          <Grid container marginTop={3} rowSpacing={2}>
            {files.map((file: File) => (
              <>
                <Grid
                  item
                  xs={12}
                  width={12}
                  m={1}
                  sx={{
                    borderRadius: 'var(--default-border-radius)',
                    padding: '20px',
                    backgroundColor: '#fbfbfb',
                  }}
                >
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    {file.name}
                    <IconButton
                      color="error"
                      onClick={() => removeFromFileList(file.name)}
                    >
                      {MaterialIcons.Close}
                    </IconButton>
                  </Box>
                </Grid>
              </>
            ))}
          </Grid>
        )}
      </Box>

      {success && (
        <>
          <Divider sx={{ marginY: 5 }} />
          <Box>
            <Typography variant="h5" fontWeight="bold">
              Uploaded files
            </Typography>
            <Grid container spacing={3} padding={4} fontSize="large">
              {urls.map(
                (url: {
                  fileName: string;
                  location: string;
                  cdnUrl: string;
                }) => (
                  <>
                    <Grid item xs={12} key={url.location}>
                      <Box display={'flex'} flexDirection={'column'}>
                        <Typography fontSize="large">{url.fileName}</Typography>
                        <a
                          href={url.cdnUrl}
                          color="secondary"
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: 'underline' }}
                        >
                          {url.cdnUrl}
                        </a>
                      </Box>
                    </Grid>
                    <Grid item xs={12} key={url.location}></Grid>
                  </>
                ),
              )}
            </Grid>
          </Box>
        </>
      )}
      {/* 
      {urls.length !== 0 && (
        <>
          <Typography variant='h6' fontWeight='bold'>
            Recent uploads
          </Typography>
          <ul>
            {urls.map((url: string) => (
              <li key={url}>{url}</li>
            ))}
          </ul>
        </>
      )} */}
    </>
  );
};

export default UploadFileView;