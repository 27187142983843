import { Box, Divider } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Config from '../../../config';
import { ApiConfigV2 } from '../../../shared/apiConfigV2';
import { useQueryString } from '../../../utilities/hooks/useQueryString';
import AuditLogs from '../../components/AuditLogs/AuditLogs';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import TableViewComponent from '../../components/TableViewComponent/TableViewComponent';
import ConfirmDialog from '../../components/confirmDialog/ConfirmDialog';
import { LoadingView } from '../../components/loadingView';
import { RootViewContext } from '../RootView';
import { useGetGeneralRequests } from './hooks/useGetGeneralRequests';
import { useUpdateRequests } from './hooks/useUpdateRequest';
import { GeneralRequestsGridColumns } from './utils/grid-columns/GeneralRequestGridColumns';

const DefaultPageSize = 10;

const GeneralRequestView = () => {
  const queryString = useQueryString({
    arrayFormat: 'separator',
    arrayFormatSeparator: ',',
  });
  const { setAppBreadCrumbs } = useContext(RootViewContext);
  const [pageSize, setPageSize] = useState<number>(
    queryString?.pageSize ? +queryString?.pageSize : DefaultPageSize,
  );
  const [page, setPage] = useState<number>(
    queryString?.page ? +queryString?.page : 0,
  );
  const { data, isLoading, refetch } = useGetGeneralRequests(
    page,
    pageSize,
    'general_request',
  );
  const { mutateAsync, isLoading: isUpdating } = useUpdateRequests();

  const [showApproveConfirmation, setShowApproveConfirmation] = useState<{
    open: boolean;
    msg: string;
    requestId: number | null;
  }>({ open: false, msg: '', requestId: null });

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<{
    open: boolean;
    msg: string;
    requestId: number | null;
  }>({ open: false, msg: '', requestId: null });
  const [refetchAuditLogs, setRefetchAuditLogs] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleRefetchAuditLogs = useCallback(() => {
    setRefetchAuditLogs(!refetchAuditLogs);
  }, [refetchAuditLogs, setRefetchAuditLogs]);

  /**
   * Handler for Updating the request based on type
   *
   * 1 -> Approve
   * 2 -> Reject
   * @param requestId
   * @param type
   * @returns
   */
  const handleAccessCodeRequest = async (requestId: number, type: string) => {
    try {
      if (!requestId || !type) return;
      if (type === '2') {
        setShowDeleteConfirmation({
          open: true,
          msg: 'Are you sure you want to reject this request?',
          requestId: requestId,
        });
      } else {
        setShowApproveConfirmation({
          open: true,
          msg: 'Are you sure you want to approve this request?',
          requestId: requestId,
        });
      }
    } catch (err) {
      toast.error('Error occurred while attempting to update  request');
    }
  };

  const onRejectConfirm = async (requestId: number) => {
    try {
      setLoading(true);
      await mutateAsync({
        reqId: requestId,
        status: 'rejected',
        accessCode: null,
        type: 'GENERAL_REQUESTS',
      });
      toast.success('Request rejected successfully');
      refetch();
      handleRefetchAuditLogs();
    } catch (err) {
      toast.error('Error occurred while rejecting the request');
    } finally {
      setLoading(false);
      setShowDeleteConfirmation({ open: false, msg: '', requestId: null });
    }
  };

  // Handling approval confirmation
  const onApproveConfirm = async (requestId: number) => {
    try {
      setLoading(true);
      await mutateAsync({
        reqId: requestId,
        status: 'approved',
        accessCode: null,
        type: 'GENERAL_REQUESTS',
      });
      toast.success('Request approved successfully');
      refetch();
      handleRefetchAuditLogs();
    } catch (err) {
      toast.error('Error occurred while approving the request');
    } finally {
      setLoading(false);
      setShowApproveConfirmation({ open: false, msg: '', requestId: null });
    }
  };

  useEffect(() => {
    setAppBreadCrumbs([
      {
        label: 'General Requests',
        path: Config.paths.triaing.generalRequests,
        type: 'link',
      },
      {
        label: 'General Requests',
        type: 'text',
      },
    ]);
  }, []);

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <Box>
      <SectionHeader title={'General Requests'} />

      <Divider sx={{ my: 5 }} />

      <TableViewComponent
        rows={data.membershipRequests ?? []}
        columns={GeneralRequestsGridColumns({ handleAccessCodeRequest })}
        pageSizeCustom={pageSize}
        currentPage={page}
        getRowId={(row: any) => row?.id}
        loading={isLoading}
        onPageSizeChange={(pageSize) => {
          setPageSize(pageSize);
        }}
        onPageChange={(page) => {
          setPage(page);
        }}
        paginationMode="server"
        total={data.total}
      />

      <ConfirmDialog
        open={showDeleteConfirmation.open}
        handleClose={() =>
          setShowDeleteConfirmation({ open: false, msg: '', requestId: null })
        }
        onConfirm={() => {
          if (showDeleteConfirmation.requestId !== null) {
            onRejectConfirm(showDeleteConfirmation.requestId);
          }
        }}
        title="Confirm Rejection?"
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Reject"
      >
        {showDeleteConfirmation.msg ||
          'Are you sure you want to reject this request'}
      </ConfirmDialog>

      <ConfirmDialog
        open={showApproveConfirmation.open}
        handleClose={() =>
          setShowApproveConfirmation({ open: false, msg: '', requestId: null })
        }
        onConfirm={() => {
          if (showApproveConfirmation.requestId !== null) {
            onApproveConfirm(showApproveConfirmation.requestId);
          }
        }}
        title="Confirm Approval?"
        cancelButtonTitle="Go Back"
        confirmButtonTitle="Proceed"
        disabled={isUpdating}
      >
        {
          <div>
            <h3>Before You Proceed…</h3>
            <p>
              <strong>To grant access, please ensure you’ve either:</strong>
            </p>
            <ul>
              <li>Emailed the access code to the user, OR</li>
              <li>Moved them to an organization via the admin dashboard.</li>
            </ul>
            <p>Proceed only after completing one of these steps.</p>
          </div>
        }
      </ConfirmDialog>
      <AuditLogs
        customUrl={ApiConfigV2.api.auditlog.getGeneralRequestsAuditLogs}
        refetch={refetchAuditLogs}
      />
    </Box>
  );
};

export default GeneralRequestView;
