import {
  IconButton,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import {
  ConfigItem,
  OrganisationAccessCode,
} from '../../../../../shared/types/organisation/OrganisationDetailItem';

import { ThreeBarsIcon } from '@primer/octicons-react';
import toast from 'react-hot-toast';
import { MaterialIcons } from '../../../../../assets/MaterialIcons';
import { ApiConfigV2 } from '../../../../../shared/apiConfigV2';
import { OrganisationStatusInList } from '../../../../../shared/constants/Organisation';
import {
  OrganisationServiceV2,
  UniteService,
} from '../../../../../shared/service/services_v2';
import { getUserCookie } from '../../../../../utilities/Api';
import SectionHeader from '../../../../components/SectionHeader/SectionHeader';
import StyledButton from '../../../../widgets/styledButton/StyledButton';
import { OrganisationoDetailContext } from './OrganisationDetailView';
import { useGetOrgConfigs } from './hooks/useGetOrgConfigs';

const OrganisationDetailHeader = ({
  accessCodes,
  activateMembers,
}: {
  accessCodes: OrganisationAccessCode[] | null;
  activateMembers: () => void;
}) => {
  const {
    getOrgData: refreshOrg,
    orgData,
    updatedData,
    refetchAuditLogs,
    setRefetchAuditLogs,
    membersInMultipleTeams,
    totalMembers,
  } = useContext(OrganisationoDetailContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [filter, setFilter] = useState<string>('');

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const analyticsURL = useMemo(() => {
    return `${
      ApiConfigV2.base.insightsDashboard
    }/admin?organisationId=${orgData?.id}&token=${getUserCookie()}`;
  }, [orgData]);

  const [showStatusEdit, setShowStatusEdit] = useState(false);

  const [showHRISSyncButton, setShowHRISSyncButton] = useState(true);

  const handleStatusUpdate = async (status: number) => {
    try {
      if (!orgData) return;

      const response = await OrganisationServiceV2.updateStatus(
        orgData?.id,
        status,
      );
      if (response && response.data.success) {
        refreshOrg();
        setShowStatusEdit(false);
        setRefetchAuditLogs(!refetchAuditLogs);
        toast.success('Status updated successfully');
      }
    } catch (error) {
      toast.error('Error occurred while updating status');
    }
  };
  const handleHRISSync = async () => {
    try {
      if (!orgData?.hrisMap?.serviceAccountId) {
        return;
      }
      UniteService.syncHRISData(orgData?.hrisMap?.serviceAccountId);
      toast.success('HRIS Sync has started successfully');
      return;
    } catch (error) {
      toast.error('Error occurred while syncing hris');
    }
  };

  return (
    <>
      <SectionHeader
        title={orgData?.name ?? ''}
        subTitle={
          <>
            {orgData && (
              <Typography variant="subtitle1">
                {totalMembers !== null && totalMembers !== undefined
                  ? `${totalMembers} ${
                      totalMembers === 1 ? 'member' : 'members'
                    }`
                  : 'Members loading...'}
              </Typography>
            )}
            {accessCodes?.length !== 0 && !orgData?.defaultTeamId ? (
              <Typography color="error" variant="subtitle1" fontSize={14}>
                Warning: Access code will only work with default team.
              </Typography>
            ) : (
              <></>
            )}
            {membersInMultipleTeams?.length ? (
              <Typography color="error" variant="subtitle1" fontSize={14}>
                Warning: Users exist in multiple teams.
              </Typography>
            ) : (
              <></>
            )}
          </>
        }
        rightElement={
          <>
            <StyledButton
              variant="ghost"
              size="large"
              onClick={handleClick}
              margin={0}
              startIcon={<ThreeBarsIcon size={18} />}
              sx={{
                backgroundColor: 'var(--chip-grey-bg-color)',
                color: 'var(--chip-grey-color)',
                borderRadius: 5,
              }}
            >
              Menu
            </StyledButton>
            <Menu
              id="organisation-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={async () => {
                  activateMembers();
                  handleClose();
                }}
              >
                Activate members
              </MenuItem>
            </Menu>
            <StyledButton
              variant="ghost"
              size="large"
              onClick={() => window.open(analyticsURL, '_blank')}
              startIcon={MaterialIcons.Leads}
              sx={{
                backgroundColor: 'var(--chip-yellow-bg-color)',
                color: 'var(--chip-yellow-color)',
                borderRadius: 5,
              }}
            >
              Analytics
            </StyledButton>
            {orgData?.isAdmin && !!orgData?.hrisMap?.serviceAccountId && (
              <StyledButton
                disabled={!showHRISSyncButton}
                size="large"
                onClick={async () => {
                  handleHRISSync();
                  setShowHRISSyncButton(false);
                }}
                startIcon={MaterialIcons.Sync}
                sx={{
                  borderRadius: 5,
                }}
              >
                Sync HRIS Data
              </StyledButton>
            )}

            {!showStatusEdit && orgData ? (
              <StyledButton
                variant="ghost"
                startIcon={MaterialIcons.Edit}
                sx={{
                  backgroundColor:
                    OrganisationStatusInList?.[orgData?.status]?.bgColor,
                  color: OrganisationStatusInList?.[orgData?.status]?.color,
                  borderRadius: 5,
                }}
                onClick={() => setShowStatusEdit(true)}
              >
                {OrganisationStatusInList?.[orgData?.status]?.title ??
                  'Assign Status'}
              </StyledButton>
            ) : (
              <>
                <Tooltip title="Cancel">
                  <IconButton
                    sx={{ color: 'red' }}
                    onClick={() => {
                      setShowStatusEdit(false);
                    }}
                  >
                    {MaterialIcons.Close}
                  </IconButton>
                </Tooltip>
                <Select
                  onChange={(event: SelectChangeEvent<string>) =>
                    handleStatusUpdate(+event?.target?.value)
                  }
                  id="org-status"
                  value={updatedData?.status ?? ''}
                  size="small"
                  sx={{ width: '150px' }}
                >
                  {Object.entries(OrganisationStatusInList).map(
                    ([key, value]) =>
                      key !== '0' && (
                        <MenuItem key={`orgstatus-${key}`} value={key}>
                          {value.title}
                        </MenuItem>
                      ),
                  )}
                </Select>
              </>
            )}
          </>
        }
      />
    </>
  );
};

export default OrganisationDetailHeader;
