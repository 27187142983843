import { Grid, InputLabel, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

import { OrganisationAccessCode } from '../../../../../../../shared/types/organisation/OrganisationDetailItem';
import { GenericDialog } from '../../../../../../components/genericDialog';
import StyledButton from '../../../../../../widgets/styledButton/StyledButton';
import { useFormik } from 'formik';
import { addAccessCodeValidationSchema } from '../../../../utils/schemas/ValidateAddAccessCode.schema';
import { MarketingServiceV2 } from '../../../../../../../shared/service/services_v2';
import toast from 'react-hot-toast';
import LabelWithAsterik from '../../../../../../components/LabelWithAsterik/LabelWithAsterik';

interface IAddAccessCodeInput {
  code: string;
  maxUsage: number | null;
  domainRestriction: string;
}
const AddAccessCodeDialog = ({
  orgId,
  handleClose,
  handleSuccess,
  open,
  edit,
  accessCode,
}: {
  orgId: string;
  handleClose: () => void;
  handleSuccess: () => void;
  open: boolean;
  edit: boolean;
  accessCode: OrganisationAccessCode | null;
}) => {
  const [newAccessCode, setNewAccessCode] = useState<
    Partial<OrganisationAccessCode>
  >({
    code: accessCode?.code ?? '',
    maxUsage: accessCode?.maxUsage ?? 0,
    domainRestriction: accessCode?.domainRestriction ?? '',
  });

  useEffect(() => {
    if (accessCode) {
      setNewAccessCode({
        code: accessCode?.code ?? '',
        maxUsage: accessCode?.maxUsage ?? 0,
        domainRestriction: accessCode?.domainRestriction ?? '',
      });
    }
  }, [accessCode, open, edit]);

  const { values, errors, touched, handleChange, handleSubmit, resetForm } =
    useFormik<IAddAccessCodeInput>({
      enableReinitialize: true,
      initialValues: {
        code: accessCode?.code ?? '',
        maxUsage: accessCode?.maxUsage ?? null,
        domainRestriction: accessCode?.domainRestriction ?? '',
      },
      validationSchema: addAccessCodeValidationSchema,
      onSubmit: async () => {
        handleSubmitAccessCode();
      },
    });

  const handleSubmitAccessCode = async () => {
    let response = null;

    try {
      if (edit && accessCode?.code) {
        response = await MarketingServiceV2.updateB2BAccessCode(
          values as Partial<OrganisationAccessCode>,
          accessCode?.code,
        );
      } else if (!edit) {
        response = await MarketingServiceV2.addB2BAccessCode(
          values as Partial<OrganisationAccessCode>,
          Number(orgId),
        );
      }

      if (response && response.data.success) {
        toast.success('Access code added successfully!');
        resetForm();
        handleSuccess();
      }
    } catch (error: any) {
      if (error.response?.status === 409) {
        toast.error('Access code is already in use');
      } else {
        toast.error(
          error?.response?.data?.message ??
            'An error occurred while attempting to add the organisation.',
        );
      }
    }
  };

  return (
    <GenericDialog
      handleClose={handleClose}
      noFullScreen
      open={!!open}
      title={`${edit ? 'Edit' : 'Add'} Access Code`}
      extraElements={
        <StyledButton
          size="large"
          sx={{ width: '8rem' }}
          disabled={newAccessCode?.domainRestriction === 'NA'}
          onClick={() => handleSubmit()}
        >
          Submit
        </StyledButton>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputLabel>
            <LabelWithAsterik title="Code" />
          </InputLabel>
          <TextField
            fullWidth
            size="small"
            id="standard-access-code"
            placeholder="Access Code"
            disabled={edit}
            name="code"
            onChange={handleChange}
            value={values?.code ?? ''}
            error={touched?.code && Boolean(errors?.code)}
            helperText={touched?.code && errors?.code}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel>
            <LabelWithAsterik title="Max usage" />
          </InputLabel>
          <TextField
            fullWidth
            size="small"
            id="standard-max-usage"
            type="number"
            placeholder="Max usage"
            name="maxUsage"
            onChange={handleChange}
            value={values?.maxUsage ?? ''}
            error={touched?.maxUsage && Boolean(errors?.maxUsage)}
            helperText={touched?.maxUsage && errors?.maxUsage}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Domain restriction</InputLabel>
          <TextField
            fullWidth
            size="small"
            id="standard-domain-restriction"
            placeholder="Enter a Domain name (Optional)"
            name="domainRestriction"
            onChange={handleChange}
            value={values?.domainRestriction ?? ''}
            error={
              touched?.domainRestriction && Boolean(errors?.domainRestriction)
            }
            helperText={touched?.domainRestriction && errors?.domainRestriction}
          />
        </Grid>
      </Grid>
    </GenericDialog>
  );
};

export default AddAccessCodeDialog;
