import { Box } from '@mui/material';
import { GenericDialog } from '../../../components/genericDialog';
import UploadFileView from '../../../components/fileUpload/uploadFileV2';

const OnsiteCardUploadDialog = ({
  open,
  onUploadSuccess,
  handleClose,
}: {
  open: boolean;
  onUploadSuccess: (uploadUrl: string) => void;
  handleClose: () => void;
}) => (
  <GenericDialog
    open={open}
    handleClose={handleClose}
    noFullScreen
    title="Upload image"
  >
    <Box padding={5}>
      <UploadFileView
        onSuccess={onUploadSuccess}
        accept={{ 'image/jpeg': ['.jpeg', '.png'] }}
      />
    </Box>
  </GenericDialog>
);

export default OnsiteCardUploadDialog;
