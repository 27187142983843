import { useQuery } from '@tanstack/react-query';
import { OnSiteDeploymentService } from '../../../../shared/service/services_v2';
import { ProviderProfile } from '../../../../shared/types/provider/Provider';

export const useGetOnsiteProviders = () =>
  useQuery({
    queryKey: ['onsite-providers'],
    queryFn: () => OnSiteDeploymentService.getOnSiteProviders(),
    staleTime: 30 * 60 * 1000, // Data is fresh for 30 minutes
    cacheTime: 40 * 60 * 1000, // Data is kept in cache for 40 minutes
    select: (data) => (data?.data?.data ?? []) as ProviderProfile[],
    meta: {
      errorMessage:
        'An error occurred while attempting to get onsite providers.',
    },
  });
