import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { TeamMemberStatusEnum } from '../../../../../../shared/constants/Organisation';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { OrganisationTeamItem } from '../../../../../../shared/types/organisation/OrganisationTeamItem';
import { OrganisationTeamMemberItem } from '../../../../../../shared/types/organisation/OrganisationTeamMemberItem';
import ConfirmDialog from '../../../../../components/confirmDialog/ConfirmDialog';

const DeleteTeamDialog = ({
  open,
  handleClose,
  refreshTeams,
  teamToDelete,
  orgId,
}: {
  open: boolean;
  handleClose: () => void;
  refreshTeams: () => void;
  teamToDelete: OrganisationTeamItem | null;
  orgId: number;
}) => {
  const [teamData, setTeamData] = useState<Partial<OrganisationTeamItem>>({});

  const [openTeamDeleteDialog, setShowDeleteTeamDialog] =
    useState<boolean>(false);

  const [teamMembers, setTeamMembers] = useState<OrganisationTeamMemberItem[]>(
    [],
  );

  const getMembersOfTeam = useCallback(async () => {
    if (teamData.id) {
      let response = null;

      try {
        response = await OrganisationServiceV2.getMembersByTeamIdV2(
          teamData.id,
        );

        if (response && response.data.success) {
          const members = response?.data?.data?.members ?? [];
          setTeamMembers(members);
          const checkForActiveUsers = members.find(
            (obj: any) => obj?.status === TeamMemberStatusEnum.ACTIVE,
          );
          if (!checkForActiveUsers) {
            setShowDeleteTeamDialog(true);
          } else {
            toast.error('Team includes active members!');
          }
        }
      } catch (error) {
        toast.error(
          "An error occurred while attempting to get this team's members.", // eslint-disable-line quotes
        );
      }
    }
  }, [teamData.id]);

  useEffect(() => {
    if (teamToDelete) {
      setTeamData(teamToDelete);
    }
  }, [teamToDelete]);

  useEffect(() => {
    if (teamData.id) {
      getMembersOfTeam();
    }
  }, [getMembersOfTeam, teamData.id]);

  // For Add-ons
  const refreshData = () => {
    refreshTeams();
  };

  const handleDeleteTeam = async () => {
    let response = null;
    try {
      if (teamToDelete?.id) {
        response = await OrganisationServiceV2.deleteTeam(teamToDelete?.id);
        if (response && response.data.success) {
          toast.success('Team deleted successfully!');
          setShowDeleteTeamDialog(false);
          refreshTeams();
        }
      }
    } catch (err: any) {
      if (err.response?.status === 422) {
        toast.error('Found some active members in the team');
      } else {
        toast.error('An error occurred while attempting to delete a team.');
      }
    }
  };

  return (
    <ConfirmDialog
      open={openTeamDeleteDialog}
      handleClose={() => setShowDeleteTeamDialog(false)}
      title="Delete Team?"
      onConfirm={handleDeleteTeam}
    >
      {teamMembers.length
        ? `Are you sure you want to delete the team ${teamData.name} as it has ${teamMembers.length} member(s)?`
        : `Are you sure you want to delete the team ${teamData.name}?`}
    </ConfirmDialog>
  );
};

export default DeleteTeamDialog;
