import moment from 'moment';
import * as Yup from 'yup';

const eventSessionsSchema = (event: string) =>
  Yup.object().shape({
    from: Yup.string().required('From time is required').trim(),
    to: Yup.string().required('To time is required').trim(),
    room: Yup.string().required('Room is required').trim(),
    roomGuide: Yup.string().trim(),
    topics: ['rise', 'intellect_wellbeing'].includes(event)
      ? Yup.string().required('Topic Covered is required').trim()
      : Yup.string().trim(),
    overview: ['rise', 'intellect_wellbeing'].includes(event)
      ? Yup.string().required('Overview is required').trim()
      : Yup.string().trim(),
    redirectUrl: ['rise', 'intellect_wellbeing'].includes(event)
      ? Yup.string().required('Redirect URL is required').trim()
      : Yup.string().trim(),
  });

export const addOnsiteDeploymentFormValidationSchema = Yup.object().shape({
  organisationId: Yup.number()
    .typeError('Organisation is invalid')
    .required('Organisation is required'),
  locationId: Yup.number()
    .typeError('Address is invalid')
    .required('Address is required'),
  onSiteType: Yup.string()
    .typeError('Type is invalid')
    .required('Type is required')
    .trim(),
  fromDate: Yup.string()
    .typeError('From Date is invalid')
    .required('From Date is required')
    .trim(),
  toDate: Yup.string()
    .typeError('To Date is invalid')
    .required('To Date is required')
    .trim(),
  fromTime: Yup.string()
    .typeError('From Time is invalid')
    .required('From Time is required')
    .trim(),
  toTime: Yup.string()
    .typeError('To Time is invalid')
    .required('To Time is required')
    .trim(),
  cardImageUrl: Yup.string()
    .typeError('Card Image URL is invalid')
    .required('Card Image URL is required')
    .trim(),
  events: Yup.array()
    .of(
      Yup.object().shape({
        eventType: Yup.string().required('Event Type is required').trim(),
        sessions: Yup.array()
          .when('[eventType]', ([eventType], schema) => {
            return schema.of(eventSessionsSchema(eventType));
          })
          .min(1, 'Atleast 1 session is required'),
      }),
    )
    .min(1, 'Atleast 1 event is required to setup onsite'),
  providerId: Yup.string()
    .typeError('Provider is invalid')
    .required('Provider is required')
    .trim(),
  providerOverview: Yup.string()
    .typeError('Overview is invalid')
    .required('Overview is required')
    .trim(),
});
