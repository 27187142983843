export enum OnSiteDeploymentEvents {
  RISE = 'rise',
  WELL_BEING = 'intellect_wellbeing',
  COUNSELLING = 'one_to_one_councelling',
}

export const OnSiteDeploymentEventsMapping: Record<string, string> = {
  [OnSiteDeploymentEvents.RISE]: 'Rise',
  [OnSiteDeploymentEvents.WELL_BEING]: 'Well Being',
  [OnSiteDeploymentEvents.COUNSELLING]: '1:1 Counselling',
};
export enum OnSiteTypes {
  EIGHT_HOUR = 'eight_hour_event',
  FOUR_HOUR_NIGHT = 'four_hour_night_event',
}

export const OnSiteTypesMapping: Record<string, string> = {
  [OnSiteTypes.EIGHT_HOUR]: '8 Hour',
  [OnSiteTypes.FOUR_HOUR_NIGHT]: '4 Hour (Night)',
};

export enum OnSiteStatus {
  DONE = 'done',
  DRAFT = 'draft',
  LIVE = 'live',
  CANCELLED = 'cancelled',
}

export const OnSiteStatusLabelColors = {
  [OnSiteStatus.DRAFT]: {
    title: 'Draft',
    color: 'var(--chip-grey-color)',
    bgColor: 'var(--chip-grey-bg-color)',
  },
  [OnSiteStatus.CANCELLED]: {
    title: 'Cancelled',
    color: 'var(--chip-red-color)',
    bgColor: 'var(--chip-red-bg-color)',
  },
  [OnSiteStatus.DONE]: {
    title: 'Done',
    color: 'var(--chip-blue-color)',
    bgColor: 'var(--chip-blue-bg-color)',
  },
  [OnSiteStatus.LIVE]: {
    title: 'Live',
    color: 'var(--chip-green-color)',
    bgColor: 'var(--chip-green-bg-color)',
  },
};

export const OnSiteDeploymentOpsSites = [
  'Jakarta',
  'Gurgaon',
  'Mumbai',
  'Tokyo',
  'Seoul',
  'Kuala Lumpur',
  'Manila',
  'Singapore',
  'Bangkok',
  'Ho Chi Minh',
];

/**
 * Used for removing specific fields from an object
 * @param obj
 * @param fieldsToRemove
 * @returns
 */
export const sanitizeOnsiteDetailsObject = (
  obj: Record<string, any>,
  fieldsToRemove: string[],
): Record<string, any> => {
  if (Array.isArray(obj)) {
    return obj.map((item) => sanitizeOnsiteDetailsObject(item, fieldsToRemove));
  } else if (obj !== null && typeof obj === 'object') {
    const newObj: Record<string, any> = {};
    for (const key in obj) {
      if (!fieldsToRemove.includes(key)) {
        newObj[key] = sanitizeOnsiteDetailsObject(obj[key], fieldsToRemove);
      }
    }
    return newObj;
  }
  return obj;
};
