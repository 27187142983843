import { Tab, Tabs } from '@mui/material';
import { useContext, useState } from 'react';
import TeamView from './organisationTeams/TeamView';
import { OrganisationoDetailContext } from './OrganisationDetailView';
import DomainUsersView from './organisationNonMembers/DomainUsersView';
import AccessCodeUsersView from './organisationNonMembers/AccessCodeUsersView';
import MemberTableRevised from './organisationMembers/MemberTableRevised';

const OrganisationManagement = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const { orgData } = useContext(OrganisationoDetailContext);

  return (
    <>
      <Tabs
        value={selectedTabIndex}
        onChange={(_event, newValue) => {
          setSelectedTabIndex(newValue);
        }}
        aria-label="Course detail tabs"
        sx={{ mb: 3 }}
      >
        <Tab label="Teams" value={0} />
        <Tab label="Members" value={1} />
        <Tab label="Domain Users" value={2} />
        <Tab label="AccessCode Users" value={3} />
      </Tabs>

      {selectedTabIndex === 0 && orgData ? <TeamView /> : <></>}
      {selectedTabIndex === 1 && orgData ? (
        <MemberTableRevised orgId={orgData?.id} />
      ) : (
        <></>
      )}
      {selectedTabIndex === 2 && orgData ? (
        <DomainUsersView orgId={orgData?.id} />
      ) : (
        <></>
      )}
      {selectedTabIndex === 3 && orgData ? (
        <AccessCodeUsersView orgId={orgData?.id} />
      ) : (
        <></>
      )}
    </>
  );
};

export default OrganisationManagement;
