import { ApiConfigV2 } from '../../apiConfigV2';
import { OnSiteStatus } from '../../constants/OnsiteDeployment';
import { NetworkInstanceV2 } from './NetworkInstance';

export class OnSiteDeployment {
  client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  getAllDeployments = (page: number, pageSize: number) =>
    this.client.get(ApiConfigV2.api.onSiteDeployment.getOnSiteDeploymentList, {
      params: { page, pageSize },
    });

  getOnSiteDeploymentDetails = (onSiteId: string) =>
    this.client.get(
      ApiConfigV2.api.onSiteDeployment.getOnSiteDeploymentDetails?.replace(
        '{{onSiteId}}',
        onSiteId,
      ),
    );

  getOnSiteDeploymentMeetings = (onSiteId: string) =>
    this.client.get(
      ApiConfigV2.api.onSiteDeployment.getOnSiteDeploymentMeetings?.replace(
        '{{onSiteId}}',
        onSiteId,
      ),
    );

  createOnSiteDeployment = (payload: Record<string, any>) =>
    this.client.post(
      ApiConfigV2.api.onSiteDeployment.createOnSiteDeployment,
      payload,
    );

  updateOnSiteDeployment = (onSiteId: string, payload: Record<string, any>) => {
    return this.client.put(
      ApiConfigV2.api.onSiteDeployment.updateOnSiteDeploymentDetails.replace(
        '{{onSiteId}}',
        onSiteId,
      ),
      payload,
    );
  };

  updateOnSiteDeploymentStatus = (onSiteId: string, status: OnSiteStatus) =>
    this.client.put(
      ApiConfigV2.api.onSiteDeployment.updateOnSiteDeploymentStatus.replace(
        '{{onSiteId}}',
        onSiteId,
      ),
      { status },
    );

  deleteOnSiteEvent = (eventId: number) =>
    this.client.delete(
      ApiConfigV2.api.onSiteDeployment.deleteOnSiteDeploymentEvent.replace(
        '{{eventId}}',
        eventId.toString(),
      ),
    );

  deleteOnSiteSession = (sessionId: number) =>
    this.client.delete(
      ApiConfigV2.api.onSiteDeployment.deleteOnSiteDeploymentSession.replace(
        '{{sessionId}}',
        sessionId.toString(),
      ),
    );

  getOnSiteDeploymentLocations = () =>
    this.client.get(ApiConfigV2.api.onSiteDeployment.getOnSiteLocations);

  getOnSiteProviders = () =>
    this.client.get(ApiConfigV2.api.onSiteDeployment.getOnSiteProviders);
}
