import { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import TableViewComponent from '../../../components/TableViewComponent/TableViewComponent';
import { OnSiteMeetingColumns } from './MeetingColumns';
import { OnSiteDeploymentService, AppServiceV2 } from '../../../../shared/service/services_v2';
import { LoadingView } from '../../../components/loadingView';
import { RootViewContext } from '../../RootView';
import StyledButton from '../../../widgets/styledButton/StyledButton';
import { MaterialIcons } from '../../../../assets/MaterialIcons';
import { UserSMSModal } from './UserSMS';

export const OnsiteMeetings = () => {
  const { onSiteId }: { onSiteId: string } = useParams();
  const { setAppBreadCrumbs } = useContext(RootViewContext);
  const [meetings, setMeetings] = useState<[] | undefined>(undefined);
  const [selectedMeetings, setSelectMeetings] = useState<number[]>([]);
  const [showSendSMS, setSendSMS] = useState<boolean>(false);
  const getData = async () => {
    const response = await OnSiteDeploymentService.getOnSiteDeploymentMeetings(
      onSiteId,
    );
    setMeetings(response.data.data);
  };

  const sendSms = async (message: string) => {
    try {
      const response = await AppServiceV2.sendSMS({
        userId: selectedMeetings.map(m => m),
        message,
      });
      toast.success(`SMS triggered for ${selectedMeetings.length} users`);
    }
    catch (e) {
      console.error(e);
      toast.error('SMS Error. Please contact #tech_support');
    }
  };

  useEffect(() => {
    getData();
    setAppBreadCrumbs([
      { label: 'Deployments', type: 'link', path: '/onsite-sessions' },
      { label: onSiteId, type: 'link', path: '/onsite-sessions/details/' + onSiteId },
      {
        label: 'Meetings',
        type: 'link',
        path: '/onsite-sessions/details/' + onSiteId + '/meetings',
      },

    ]);

  }, []);

  if (!meetings) return <LoadingView />;

  return <Box>
    {!!selectedMeetings.length &&
      <Box display='flex' justifyContent={'space-between'}>
        <Typography variant='subtitle1'>
          <b>{selectedMeetings.length}</b> User
          {selectedMeetings.length !== 1 && 's'} selected
          <StyledButton
            color='gray'
            variant='ghost'
            startIcon={MaterialIcons.Close}
            onClick={() => setSelectMeetings([])}
            sx={{ margin: 0 }}
          >
            Clear Selection
          </StyledButton>
        </Typography>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <StyledButton
            color='gray'
            variant='ghost'
            startIcon={MaterialIcons.PersonIcon}
            onClick={() => {
              setSendSMS(true);
            }}
          >
            Send SMS
          </StyledButton>
        </Box>
      </Box>}
    <TableViewComponent
      rows={meetings
      .filter((m: any) => m.meetingStatus !== '3')
      .sort((m1: any, m2: any) => m1.meetingTime - m2.meetingTime) || []}
      columns={OnSiteMeetingColumns()}
      pageSizeCustom={100}
      getRowId={(row: any) => row?.userId}
      loading={false}
      paginationMode='server'
      onRowSelection={(value) => setSelectMeetings(value as number[])}
      rowSelectionModel={selectedMeetings}
      checkboxSelection={true}
    />
    <UserSMSModal
      open={showSendSMS}
      handleClose={() => {
        setSendSMS(false);
      }}
      handleSend={(message: string) => {
        sendSms(message);
        setSendSMS(false);
      }}
    />
  </Box>;
};
