import { Switch } from 'react-router';
import Config from '../../../config';
import { AuthenticatedRoute } from '../../components/authenticatedRoute/AuthenticatedRoute';
import DomainRequestView from './DomainRequestView';
import GeneralRequestsViewIndex from './GeneralRequestManagement';

export default function DomainRequests() {
  return (
    <Switch>
      <AuthenticatedRoute
        path={`${Config.paths.triaing.domainRequests}`}
        component={DomainRequestView}
      />
      <AuthenticatedRoute
        path={`${Config.paths.triaing.generalRequests}`}
        component={GeneralRequestsViewIndex}
      />
    </Switch>
  );
}
