import { useQuery } from '@tanstack/react-query';
import { TriagingService } from '../../../../shared/service/services_v2';

export const useGetAccesscodeRequests = (page: any, pageSize: any) =>
  useQuery({
    queryKey: ['accessCodeRequests', page, pageSize],
    queryFn: async () => {
      const response = await TriagingService.getAccesscodeRequests(
        page,
        pageSize,
      );
      return response.data.data;
    },
    meta: {
      errorMessage:
        'An error occurred while attempting to get the domain requests.',
    },
  });
