import { useQuery } from '@tanstack/react-query';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';

export const useGetOrgMembersCount = (orgId?: number) =>
  useQuery({
    enabled: Boolean(orgId),
    queryKey: ['org-members-count', orgId],
    queryFn: () => {
      if (orgId) {
        return OrganisationServiceV2.getMembersCountByOrg(orgId);
      }
    },
    staleTime: 30 * 60 * 1000, // Data is fresh for 30 minutes
    cacheTime: 40 * 60 * 1000, // Data is kept in cache for 40 minutes
    select: (data) => (data?.data?.data ?? {}) as { totalMembers?: number },
    meta: {
      errorMessage:
        'An error occurred while attempting to get org members count',
    },
  });
