export const ProviderAttributeType: any = {
  '0': { title: 'Background', color: 'secondary' },
  '1': { title: 'Expertise', color: 'secondary' },
  '2': { title: 'Approach', color: 'secondary' },
  '3': { title: 'Location', color: 'secondary' },
};

export const ProviderLanguages = ['en', 'id'];

export const CoachAssignGoals: { id: number; title: string }[] = [
  { id: 1, title: 'Anxiety & worry' },
  { id: 2, title: 'Career & work' },
  { id: 3, title: 'Emotion regulation' },
  { id: 4, title: 'Health & lifestyle' },
  { id: 5, title: 'Low mood' },
  { id: 6, title: 'Relationships' },
  { id: 7, title: 'Self-confidence' },
  { id: 8, title: 'Stress & burnout' },
  { id: 10, title: 'Family & Parenting' },
];

export const CoachAssignRoles: { id: string; title: string }[] = [
  { id: '0', title: 'Coach' },
  { id: '1', title: 'Clinical' },
  { id: '2', title: 'Responder' },
  { id: '3', title: 'CoachAndClinical' },
  { id: '4', title: 'CareNavigator' },
  { id: '5', title: 'Nutritionist' },
  { id: '6', title: 'Fitness' },
  { id: '7', title: 'Financial' },
  { id: '8', title: 'Executive' },
  { id: '9', title: 'Legal' },
  { id: '10', title: 'IntellectCareOffsite' },
];

export const ExpertiseList: { [rankId: number]: string } = {
  1: 'Anxiety & worry',
  2: 'Career & work',
  3: 'Emotion regulation',
  4: 'Health & lifestyle',
  5: 'Low mood',
  6: 'Relationships',
  7: 'Self-confidence',
  8: 'Stress & burnout',
  10: 'Family & Parenting',
};

export const STATUS_TEXT: { [statusCode: string]: string } = {
  '0': 'BOOKED',
  '1': 'IN PROGRESS',
  '2': 'COMPLETED',
  '3': 'CANCELLED',
  '4': 'NO SHOW',
  '5': 'EMPTY',
  '6': 'LAST MIN CANCELLATION',
  '7': 'PROVIDER NO SHOW',
  '8': 'USER NO SHOW',
  '9': 'REQUEST TO SCHDULE',
  '10': 'REQUEST CANCELLED',
  undefined: 'NA',
};

export const STATUS_TEXT_CHIP_COLORS = (
  statusCode: string,
): { color: string; bgColor: string } => {
  switch (statusCode) {
    case '0':
      return {
        color: 'var(--chip-blue-color)',
        bgColor: 'var(--chip-blue-bg-color)',
      };
    case '1':
      return {
        color: 'var(--chip-green2-color)',
        bgColor: 'var(--chip-green2-bg-color)',
      };
    case '2':
      return {
        color: 'var(--chip-green-color)',
        bgColor: 'var(--chip-green-bg-color)',
      };
    case '3':
    case '6':
    case '7':
    case '8':
      return {
        color: 'var(--chip-red-color)',
        bgColor: 'var(--chip-red-bg-color)',
      };
    case '4':
    case '9':
      return {
        color: 'var(--chip-yellow-color)',
        bgColor: 'var(--chip-yellow-bg-color)',
      };
    case '10':
      return {
        color: 'var(--chip-purple-color)',
        bgColor: 'var(--chip-purple-bg-color)',
      };

    default:
      return {
        color: 'var(--chip-grey-color)',
        bgColor: 'var(--chip-grey-bg-color)',
      };
  }
};

export const ProviderType = {
  '0': 'ICF Coach',
  '1': 'Counsellor',
  '2': 'Clinical Psychologist',
  '3': 'Crisis Intervention',
  '4': 'Holistic',
};

// provider services
export const ProviderServiceType = {
  '0': 'Intellect Coaching',
  '1': 'Intellect Care',
  '2': 'Intellect Helpline',
  '5': 'Nutritionist',
  '6': 'Fitness',
  '7': 'Financial',
  '8': 'Executive',
  '9': 'Legal',
  '10': 'Intellect Care (Offsite)',
  '11': 'Intellect Coaching (Offsite)',
  '12': 'Intellect Care (Onsite)',
};

export const ProviderAllowedServices = {
  '0': ['0', '10'],
  '1': ['0', '1', '2', '10', '11', '12'],
  '2': ['0', '1', '2', '10', '11', '12'],
  '3': ['2'],
  '4': ['5', '6', '7', '8', '9'],
} as Record<keyof typeof ProviderType, (keyof typeof ProviderServiceType)[]>;

// export const ProviderRoleType: { [key: string]: string } = {
//   '0': 'Coach',
//   '1': 'Clinical',
//   '2': 'Responder',
//   '3': 'CoachAndClinical',
//   '4': 'CareNavigator',
//   '5': 'Nutritionist',
//   '6': 'Fitness',
//   '7': 'Financial',
//   '8': 'Executive',
//   '9': 'Legal',
//   '10': 'IntellectCareOffsite',
// };

export const ProviderStatus: {
  [id: string]: {
    label: string;
    color: string;
    bgColor: string;
    editable: boolean;
  };
} = {
  '0': {
    label: 'Coach',
    color: 'var(--chip-green2-color)',
    bgColor: 'var(--chip-green2-bg-color)',
    editable: true,
  },
  '1': {
    label: 'Clinical',
    color: 'var(--chip-green-color)',
    bgColor: 'var(--chip-green-bg-color)',
    editable: false,
  },
  '2': {
    label: 'Responder',
    color: 'var(--chip-yellow-color)',
    bgColor: 'var(--chip-yellow-bg-color)',
    editable: true,
  },
  '3': {
    label: 'CoachAndClinical',
    color: 'var(--chip-green-color)',
    bgColor: 'var(--chip-green-bg-color)',
    editable: true,
  },
  '4': {
    label: 'CareNavigator',
    color: 'var(--chip-grey-color)',
    bgColor: 'var(--chip-grey-bg-color)',
    editable: false,
  },
  '5': {
    label: 'Nutritionist',
    color: 'var(--chip-blue-color)',
    bgColor: 'var(--chip-blue-bg-color)',
    editable: true,
  },
  '6': {
    label: 'Fitness',
    color: 'var(--chip-purple-color)',
    bgColor: 'var(--chip-purple-bg-color)',
    editable: true,
  },
  '7': {
    label: 'Financial',
    color: 'var(--chip-green2-color)',
    bgColor: 'var(--chip-green2-bg-color)',
    editable: false,
  },
  '8': {
    label: 'Executive',
    color: 'var(--chip-blue-color)',
    bgColor: 'var(--chip-blue-bg-color)',
    editable: true,
  },
  '9': {
    label: 'Legal',
    color: 'var(--chip-red-color)',
    bgColor: 'var(--chip-red-bg-color)',
    editable: false,
  },
  '10': {
    label: 'IntellectCareOffsite',
    color: 'var(--chip-yellow-color)',
    bgColor: 'var(--chip-yellow-bg-color)',
    editable: false,
  },
};

export enum ProviderAuditLogEvents {
  PROFILE_UPDATED = 'Profile Updated',
  BIO_TRANSLATIONS_UPDATED = 'Bio Translations Updated',
  SERVICES_UPDATED = 'Services Updated',
  COUNTRY_CITY_UPDATED = 'Country City Updated',
  PREFERRED_CLINICS_UPDATED = 'Preferred Clinics Updated',
  LANGUAGES_UPDATED = 'Languages Updated',
  ATTRIBUTES_UPDATED = 'Attributes Updated',
  TIMEZONE_VISBILITY_UPDATED = 'TimeZone/Visibility Updated',
  USER_MATCHED = 'User Matched',
  USER_UNMATCHED = 'User Unmatched',
}

export enum CaseNoteTypes {
  PrimaryGoal = 7,
  SecondaryGoal = 8,
  RiskAssesment = 9,
  SuicidalAssesment = 10,
  SessionNotes = 11,
  InterventionsAndModality = 12,
  FormulationAndTreatment = 13,
  ProgressTowardsGoals = 14,
  Recommendations = 15,
  FollowUpPlan = 16,
  AdditonalSessionRecommended = 17,
  SharedFollowUpPlan = 18,
}

export enum CaseNotesMeetingStatus {
  scheduled = '0',
  inprogress = '1',
  completed = '2',
  cancelled = '3',
  noshow = '4',
  empty = '5',
  lastmincancelled = '6',
  providernoshow = '7',
  usernoshow = '8',
  requesttoschedule = '9',
  requestcancelled = '10',
}

export const CaseNotesMeetingStatusMapping: Record<string, string> = {
  [CaseNotesMeetingStatus.scheduled]: 'SCHEDULED',
  [CaseNotesMeetingStatus.inprogress]: 'IN-PROGRESS',
  [CaseNotesMeetingStatus.completed]: 'COMPLETED',
  [CaseNotesMeetingStatus.cancelled]: 'CANCELLED',
  [CaseNotesMeetingStatus.noshow]: 'NO-SHOW',
  [CaseNotesMeetingStatus.empty]: 'EMPTY',
  [CaseNotesMeetingStatus.lastmincancelled]: 'LAST-MIN-CANCELLED',
  [CaseNotesMeetingStatus.providernoshow]: 'PROVIDER-NO-SHOW',
  [CaseNotesMeetingStatus.usernoshow]: 'USER-NO-SHOW',
  [CaseNotesMeetingStatus.requesttoschedule]: 'REQ-TO-SCHEDULE',
  [CaseNotesMeetingStatus.requestcancelled]: 'REQ-CANCELLED',
};

export enum CaseNotesMeetingType {
  twilio = '0',
  agora = '1',
  ms = '2',
  offline = '3',
  zoomLink = '4',
  daily = '5',
  zoom = '6',
}

export const CaseNotesMeetingTypeMapping: Record<string, string> = {
  [CaseNotesMeetingType.twilio]: 'Twilio',
  [CaseNotesMeetingType.agora]: 'Agora',
  [CaseNotesMeetingType.ms]: 'Ms-Teams',
  [CaseNotesMeetingType.offline]: 'Offline',
  [CaseNotesMeetingType.zoomLink]: 'Zoom-Link',
  [CaseNotesMeetingType.daily]: 'Daily',
  [CaseNotesMeetingType.zoom]: 'Zoom',
};

export const MeetingTypeLabels: { [key: string]: string } = {
  '0': 'twilio',
  '1': 'agora',
  '2': 'ms',
  '3': 'offline',
  '4': 'zoomLink',
  '5': 'daily',
  '6': 'zoom',
};

export const MeetingSourceLabels: { [key: string]: string } = {
  '0': 'app',
  '1': 'android',
  '2': 'ios',
  '3': 'maverick',
  '4': 'notebook',
  '5': 'buddy',
  '6': 'carenav',
  '7': 'web',
  '8': 'admin',
  '9': 'providerapp',
  '10': 'system',
};
