import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { ChevronDownIcon } from '@primer/octicons-react';
import { FormikProps } from 'formik';
import { isString } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { MaterialIcons } from '../../../../assets/MaterialIcons';
import {
  OnSiteDeploymentEvents,
  OnSiteDeploymentEventsMapping,
} from '../../../../shared/constants/OnsiteDeployment';
import { OnSiteDeploymentService } from '../../../../shared/service/services_v2';
import {
  validateOnsiteSlotsOverlap,
  validateSlotsOverlap,
} from '../../../../utilities/Date';
import { ISlotRange } from '../../../components/ScheduleTimeSlots/types';
import ConfirmDialog from '../../../components/confirmDialog/ConfirmDialog';
import StyledButton from '../../../widgets/styledButton/StyledButton';
import {
  IAddOnsiteDeploymentInputs,
  IEventsDetails,
  OnsiteTimeIntervals,
} from './OnsiteSessionDetails';

const defaultSubSessionDetails = {
  from: '',
  to: '',
  room: '',
  roomGuide: '',
  topics: '',
  overview: '',
  redirectUrl: '',
};

const OnsiteEventConfiguration = ({
  formik,
  refetchOnSiteDetails,
  isEditDisabled,
}: {
  formik: FormikProps<IAddOnsiteDeploymentInputs>;
  refetchOnSiteDetails: () => Promise<void>;
  isEditDisabled: boolean;
}) => {
  const [selectedSlots, setSelectedSlots] = useState<
    Record<string, ISlotRange>
  >({});

  useEffect(() => {
    if (!formik?.values?.events) return;

    const obj = formik?.values?.events?.reduce(
      (result: Record<string, ISlotRange>, ev, eventIdx) => {
        ev?.sessions?.forEach((session, sessionIdx) => {
          const key = `events.${eventIdx}.sessions.${sessionIdx}`;
          result[key] = {
            locationId: null,
            slots: [+session?.from, +session?.to],
          };
        });
        return result;
      },
      {},
    );
    setSelectedSlots(obj);
  }, [formik?.values?.events]);

  const [loading, setLoading] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState<string>('');
  const [confirmEventDeletion, setConfirmEventDeletion] = useState<{
    open: boolean;
    eventId: number | null;
  }>({ open: false, eventId: null });
  const [confirmSessionDeletion, setConfirmSessionDeletion] = useState<{
    open: boolean;
    sessionId: number | null;
  }>({ open: false, sessionId: null });

  const validateEventSlots = ({
    eventIdx,
    sessionIdx,
    toTime,
  }: {
    eventIdx: number;
    sessionIdx: number;
    toTime: string;
  }): boolean => {
    const key = `events.${eventIdx}.sessions.${sessionIdx}`;

    const updatedSlots = deleteEventFromSlots(key);

    const validate = validateOnsiteSlotsOverlap(
      {
        locationId: null,
        slots: [
          +formik?.values?.events?.[eventIdx]?.sessions?.[sessionIdx]?.from,
          +toTime,
        ],
      },
      Object.values(updatedSlots),
    );

    if (validate) {
      toast.error('Event time is overlapping with other events');
      return false;
    }

    setSelectedSlots((prev) => ({
      ...prev,
      [`events.${eventIdx}.sessions.${sessionIdx}`]: {
        locationId: null,
        slots: [
          +formik?.values?.events?.[eventIdx]?.sessions?.[sessionIdx]?.from,
          +toTime,
        ],
      },
    }));

    return true;
  };

  const deleteEventFromSlots = (key: string, resetState = false) => {
    const newSlots = { ...selectedSlots };
    delete newSlots?.[key];
    if (resetState) {
      setSelectedSlots(newSlots);
    }

    return newSlots;
  };

  const availableEvents = useMemo(() => {
    const _addedEvents =
      formik?.values?.events?.map((ev) => ev?.eventType) ?? [];

    return Object.values(OnSiteDeploymentEvents)?.filter(
      (item) => !_addedEvents?.includes(item),
    );
  }, [formik?.values?.events]);

  const handleAddNewEvent = () => {
    if (!selectedEvent) return;

    const _filterEvents = formik?.values?.events?.filter(
      (item) => item?.eventType !== selectedEvent,
    );
    const _newEvent: IEventsDetails = {
      eventType: selectedEvent,
      sessions: [],
    };
    formik.setFieldValue('events', [..._filterEvents, _newEvent]);
    setSelectedEvent('');
  };

  const handleRemoveEvent = (index: number) => {
    if (formik?.values?.events?.[index]?.id) {
      setConfirmEventDeletion({
        open: true,
        eventId: formik?.values?.events?.[index]?.id as number,
      });
    } else {
      const _filterEvents = [...(formik?.values?.events ?? [])];

      _filterEvents.splice(index, 1);
      formik.setFieldValue('events', _filterEvents);
    }
  };

  const handleRemoveSession = (eventIdx: number, sessionIdx: number) => {
    if (formik?.values?.events?.[eventIdx]?.sessions?.[sessionIdx]?.id) {
      setConfirmSessionDeletion({
        open: true,
        sessionId: formik?.values?.events?.[eventIdx]?.sessions?.[sessionIdx]
          ?.id as number,
      });
    } else {
      const updatedEvents = [...(formik?.values?.events ?? [])];
      updatedEvents?.[eventIdx]?.sessions.splice(sessionIdx, 1);
      formik.setFieldValue('events', updatedEvents);
    }
  };

  const handleRemoveEventFromDB = async (eventId: number | null) => {
    try {
      if (!eventId) return;

      setLoading(true);
      const response = await OnSiteDeploymentService.deleteOnSiteEvent(eventId);

      if (response && response.data?.success) {
        setConfirmEventDeletion({ open: false, eventId: null });
        toast.success('Event deleted successfully.');
        setLoading(false);
        refetchOnSiteDetails();
      }
    } catch (err) {
      toast.error('Error occurred while removing event.');
    }
  };

  const handleRemoveSessionFromDB = async (sessionId: number | null) => {
    try {
      if (!sessionId) return;
      setLoading(true);

      const response = await OnSiteDeploymentService.deleteOnSiteSession(
        sessionId,
      );

      if (response && response.data?.success) {
        setConfirmSessionDeletion({ open: false, sessionId: null });
        toast.success('Session deleted successfully.');
        setLoading(false);
        refetchOnSiteDetails();
      }
    } catch (err) {
      toast.error('Error occurred while removing session.');
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <InputLabel>Types of events under deployments</InputLabel>
        <Box display={'flex'} alignItems={'center'}>
          <Select
            size="small"
            sx={{ width: '20%', marginRight: '5px' }}
            value={selectedEvent}
            onChange={(e) => setSelectedEvent(e.target.value)}
            error={formik?.touched?.events && Boolean(formik?.errors?.events)}
            disabled={isEditDisabled}
          >
            {availableEvents?.map((item, idx) => (
              <MenuItem key={idx} value={item}>
                {OnSiteDeploymentEventsMapping[item]}
              </MenuItem>
            ))}
          </Select>

          <StyledButton
            variant="ghost"
            size="small"
            margin={0}
            startIcon={MaterialIcons.Add}
            onClick={handleAddNewEvent}
            disabled={isEditDisabled}
          >
            Add
          </StyledButton>
        </Box>
        {formik?.touched?.events &&
        formik?.errors?.events &&
        isString(formik?.errors?.events) ? (
          <FormHelperText sx={{ color: 'error.main' }}>
            {formik?.errors?.events}
          </FormHelperText>
        ) : (
          <></>
        )}
        <Grid container marginTop={'8px'} rowGap={1}>
          {formik?.values?.events?.map((event, eventIdx) => (
            <Grid
              item
              xs={12}
              key={eventIdx}
              sx={{
                padding: '12px',
                border: '1px solid #DDDEE3',
                borderRadius: 'var(--default-border-radius)',
                ...(formik?.touched?.events?.[eventIdx] &&
                formik?.errors?.events?.[eventIdx] &&
                isString(formik?.errors?.events?.[eventIdx])
                  ? { borderColor: 'var(--default-error-color)' }
                  : {}),
              }}
            >
              <Box display={'flex'} justifyContent={'space-between'}>
                {OnSiteDeploymentEventsMapping?.[event?.eventType] ??
                  event?.eventType ??
                  'N/A'}

                <Box display={'flex'} gap={'20px'}>
                  <StyledButton
                    variant="ghost"
                    size="small"
                    margin={0}
                    padding={0}
                    startIcon={MaterialIcons.Add}
                    onClick={() => {
                      const updatedEvents = [...(formik?.values?.events ?? [])];
                      updatedEvents[eventIdx].sessions.push(
                        defaultSubSessionDetails,
                      );
                      formik.setFieldValue('events', updatedEvents);
                    }}
                    disabled={isEditDisabled}
                  >
                    Add Session
                  </StyledButton>
                  <StyledButton
                    variant="ghost"
                    color={'red'}
                    margin={0}
                    padding={0}
                    startIcon={MaterialIcons.Delete}
                    onClick={() => handleRemoveEvent(eventIdx)}
                    disabled={isEditDisabled}
                  >
                    Delete Event
                  </StyledButton>
                </Box>
              </Box>
              {event?.sessions?.length ? (
                <Grid container rowGap={1} mt={2}>
                  {event?.sessions?.map((session, sessionIdx) => {
                    const onsiteStartTimeSlot = +formik?.values?.fromTime;
                    // const toTime = +formik?.values?.toTime;
                    const onsiteEndTimeSlot = +formik?.values?.toTime;

                    let isSecondDaySlot = false;
                    if (onsiteEndTimeSlot < onsiteStartTimeSlot) {
                      isSecondDaySlot = true;
                    }

                    const sessionFromTimeSlot =
                      formik?.values?.events?.[eventIdx]?.sessions?.[sessionIdx]
                        ?.from;

                    const isCounsellingSession =
                      event?.eventType === OnSiteDeploymentEvents.COUNSELLING;

                    const sessionEndTimeSliceStartIdx =
                      +sessionFromTimeSlot + (isCounsellingSession ? 4 : 1); // 4 means adding 1 hr

                    let partitionSlots = false;
                    if (
                      isSecondDaySlot &&
                      sessionEndTimeSliceStartIdx > onsiteEndTimeSlot
                    ) {
                      partitionSlots = true;
                    }

                    const sessionEndTimeSliceEndIdx =
                      partitionSlots && isCounsellingSession
                        ? +sessionFromTimeSlot < 92
                          ? 0 // if fromTimeSlot is within 1st day only then we dont need to show data from second parition
                          : +sessionFromTimeSlot == 92
                          ? 1
                          : +sessionFromTimeSlot - 92 + 1 // else show data of after 1 hr slot thats why 92, and endIndex of second end partition  thats why doing 1 less minus
                        : isCounsellingSession &&
                          +sessionFromTimeSlot + 4 <= +onsiteEndTimeSlot + 1
                        ? +sessionFromTimeSlot + 5 // showing only single slot in counselling session
                        : +onsiteEndTimeSlot + 1;

                    const partitionStartTimeEndIndex = isCounsellingSession
                      ? sessionEndTimeSliceStartIdx + 1
                      : 96;

                    // const partitionEndTimeStartIndex =
                    //   +sessionFromTimeSlot < 92 ? 0 : +sessionFromTimeSlot - 92; // startIndex of second end partiion

                    const partitionEndTimeStartIndex = isCounsellingSession
                      ? +sessionFromTimeSlot < 92
                        ? 0
                        : +sessionFromTimeSlot - 92
                      : 0; // startIndex of second end partiion

                    return (
                      <Grid item key={sessionIdx} xs={12}>
                        <Accordion
                          sx={{
                            ...(formik?.touched?.events?.[eventIdx]?.sessions?.[
                              sessionIdx
                            ] &&
                            (formik?.errors?.events?.[eventIdx] as any)
                              ?.sessions?.[sessionIdx]
                              ? { borderColor: 'var(--default-error-color)' }
                              : {}),
                          }}
                        >
                          <AccordionSummary
                            sx={{
                              height: '12px',
                            }}
                            expandIcon={<ChevronDownIcon size={16} />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Typography>Session {sessionIdx + 1}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={2}>
                              <Grid item xs={3}>
                                <InputLabel>From</InputLabel>
                                <Select
                                  fullWidth
                                  size="small"
                                  label="From"
                                  defaultValue={''}
                                  value={session.from}
                                  name={`events.${eventIdx}.sessions.${sessionIdx}.from`}
                                  error={
                                    (formik?.touched?.events as any)?.[eventIdx]
                                      ?.sessions?.[sessionIdx]?.from &&
                                    Boolean(
                                      (formik?.errors?.events as any)?.[
                                        eventIdx
                                      ]?.sessions?.[sessionIdx]?.from,
                                    )
                                  }
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      `events.${eventIdx}.sessions.${sessionIdx}.from`,
                                      e.target.value,
                                    );
                                    formik.setFieldValue(
                                      `events.${eventIdx}.sessions.${sessionIdx}.to`,
                                      '',
                                    );
                                    deleteEventFromSlots(
                                      `events.${eventIdx}.sessions.${sessionIdx}`,
                                      true,
                                    );
                                  }}
                                  disabled={isEditDisabled}
                                >
                                  {(isSecondDaySlot
                                    ? [
                                        ...OnsiteTimeIntervals.slice(
                                          onsiteStartTimeSlot,
                                          96,
                                        ),
                                        ...OnsiteTimeIntervals.slice(
                                          0,
                                          onsiteEndTimeSlot -
                                            (isCounsellingSession ? 3 : 0),
                                        ),
                                      ]
                                    : OnsiteTimeIntervals?.slice(
                                        +formik?.values?.fromTime,
                                        +formik?.values?.toTime -
                                          (isCounsellingSession ? 3 : 0),
                                      )
                                  )
                                    ?.map(({ slot, time }) => ({
                                      value: slot.toString(),
                                      label: time,
                                    }))
                                    ?.map((item, idx) => {
                                      return (
                                        <MenuItem
                                          key={`${idx}-${item.value}`}
                                          value={item.value}
                                        >
                                          {item.label}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                                {(formik?.touched?.events as any)?.[eventIdx]
                                  ?.sessions?.[sessionIdx]?.from &&
                                (formik?.errors?.events as any)?.[eventIdx]
                                  ?.sessions?.[sessionIdx]?.from ? (
                                  <FormHelperText sx={{ color: 'error.main' }}>
                                    {
                                      (formik?.errors?.events as any)?.[
                                        eventIdx
                                      ]?.sessions?.[sessionIdx]?.from
                                    }
                                  </FormHelperText>
                                ) : (
                                  <></>
                                )}
                              </Grid>
                              <Grid item xs={3}>
                                <InputLabel>To</InputLabel>
                                <Select
                                  fullWidth
                                  size="small"
                                  label="From"
                                  defaultValue={''}
                                  value={session.to}
                                  disabled={
                                    !formik?.values?.events?.[eventIdx]
                                      ?.sessions?.[sessionIdx]?.from ||
                                    isEditDisabled
                                  }
                                  name={`events.${eventIdx}.sessions.${sessionIdx}.to`}
                                  onChange={(e) => {
                                    if (
                                      validateEventSlots({
                                        eventIdx,
                                        sessionIdx,
                                        toTime: e.target.value,
                                      })
                                    ) {
                                      formik.setFieldValue(
                                        `events.${eventIdx}.sessions.${sessionIdx}.to`,
                                        e.target.value,
                                      );
                                    }
                                  }}
                                  error={
                                    (formik?.touched?.events as any)?.[eventIdx]
                                      ?.sessions?.[sessionIdx]?.to &&
                                    Boolean(
                                      (formik?.errors?.events as any)?.[
                                        eventIdx
                                      ]?.sessions?.[sessionIdx]?.to,
                                    )
                                  }
                                >
                                  {(partitionSlots
                                    ? [
                                        ...OnsiteTimeIntervals.slice(
                                          sessionEndTimeSliceStartIdx,
                                          partitionStartTimeEndIndex,
                                        ),
                                        ...OnsiteTimeIntervals.slice(
                                          partitionEndTimeStartIndex,
                                          sessionEndTimeSliceEndIdx,
                                        ),
                                      ]
                                    : OnsiteTimeIntervals?.slice(
                                        sessionEndTimeSliceStartIdx,
                                        sessionEndTimeSliceEndIdx,
                                      )
                                  )
                                    .map(({ slot, time }) => ({
                                      value: slot.toString(),
                                      label: time,
                                    }))
                                    ?.map((item, idx) => {
                                      return (
                                        <MenuItem
                                          key={`${idx}-${item.value}`}
                                          value={item.value}
                                        >
                                          {item.label}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                                {(formik?.touched?.events as any)?.[eventIdx]
                                  ?.sessions?.[sessionIdx]?.to &&
                                (formik?.errors?.events as any)?.[eventIdx]
                                  ?.sessions?.[sessionIdx]?.to ? (
                                  <FormHelperText sx={{ color: 'error.main' }}>
                                    {
                                      (formik?.errors?.events as any)?.[
                                        eventIdx
                                      ]?.sessions?.[sessionIdx]?.to
                                    }
                                  </FormHelperText>
                                ) : (
                                  <></>
                                )}
                              </Grid>
                              <Grid item xs={3}>
                                <InputLabel>Room Location</InputLabel>
                                <TextField
                                  fullWidth
                                  size="small"
                                  value={session.room}
                                  name={`events.${eventIdx}.sessions.${sessionIdx}.room`}
                                  onChange={formik.handleChange}
                                  error={
                                    (formik?.touched?.events as any)?.[eventIdx]
                                      ?.sessions?.[sessionIdx]?.room &&
                                    Boolean(
                                      (formik?.errors?.events as any)?.[
                                        eventIdx
                                      ]?.sessions?.[sessionIdx]?.room,
                                    )
                                  }
                                  helperText={
                                    (formik?.touched?.events as any)?.[eventIdx]
                                      ?.sessions?.[sessionIdx]?.room &&
                                    (formik?.errors?.events as any)?.[eventIdx]
                                      ?.sessions?.[sessionIdx]?.room
                                  }
                                  disabled={isEditDisabled}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <InputLabel>Room Guide</InputLabel>
                                <TextField
                                  fullWidth
                                  size="small"
                                  value={session.roomGuide}
                                  name={`events.${eventIdx}.sessions.${sessionIdx}.roomGuide`}
                                  onChange={formik.handleChange}
                                  error={
                                    (formik?.touched?.events as any)?.[eventIdx]
                                      ?.sessions?.[sessionIdx]?.roomGuide &&
                                    Boolean(
                                      (formik?.errors?.events as any)?.[
                                        eventIdx
                                      ]?.sessions?.[sessionIdx]?.roomGuide,
                                    )
                                  }
                                  helperText={
                                    (formik?.touched?.events as any)?.[eventIdx]
                                      ?.sessions?.[sessionIdx]?.roomGuide &&
                                    (formik?.errors?.events as any)?.[eventIdx]
                                      ?.sessions?.[sessionIdx]?.roomGuide
                                  }
                                  disabled={isEditDisabled}
                                />
                              </Grid>
                              {event?.eventType !==
                                OnSiteDeploymentEvents.COUNSELLING && (
                                <>
                                  <Grid item xs={6}>
                                    <InputLabel>Topics Covered</InputLabel>
                                    <TextField
                                      fullWidth
                                      size="small"
                                      value={session.topics}
                                      name={`events.${eventIdx}.sessions.${sessionIdx}.topics`}
                                      onChange={formik.handleChange}
                                      error={
                                        (formik?.touched?.events as any)?.[
                                          eventIdx
                                        ]?.sessions?.[sessionIdx]?.topics &&
                                        Boolean(
                                          (formik?.errors?.events as any)?.[
                                            eventIdx
                                          ]?.sessions?.[sessionIdx]?.topics,
                                        )
                                      }
                                      helperText={
                                        (formik?.touched?.events as any)?.[
                                          eventIdx
                                        ]?.sessions?.[sessionIdx]?.topics &&
                                        (formik?.errors?.events as any)?.[
                                          eventIdx
                                        ]?.sessions?.[sessionIdx]?.topics
                                      }
                                      disabled={isEditDisabled}
                                      inputProps={{ maxLength: 1000 }}
                                    />
                                  </Grid>
                                  {event?.eventType !==
                                    OnSiteDeploymentEvents.COUNSELLING && (
                                    <Grid item xs={6}>
                                      <InputLabel>Redirect URL</InputLabel>
                                      <TextField
                                        fullWidth
                                        size="small"
                                        value={session.redirectUrl}
                                        name={`events.${eventIdx}.sessions.${sessionIdx}.redirectUrl`}
                                        onChange={formik.handleChange}
                                        error={
                                          (formik?.touched?.events as any)?.[
                                            eventIdx
                                          ]?.sessions?.[sessionIdx]
                                            ?.redirectUrl &&
                                          Boolean(
                                            (formik?.errors?.events as any)?.[
                                              eventIdx
                                            ]?.sessions?.[sessionIdx]
                                              ?.redirectUrl,
                                          )
                                        }
                                        helperText={
                                          (formik?.touched?.events as any)?.[
                                            eventIdx
                                          ]?.sessions?.[sessionIdx]
                                            ?.redirectUrl &&
                                          (formik?.errors?.events as any)?.[
                                            eventIdx
                                          ]?.sessions?.[sessionIdx]?.redirectUrl
                                        }
                                        disabled={isEditDisabled}
                                      />
                                    </Grid>
                                  )}
                                  <Grid item xs={6}>
                                    <InputLabel>Overview</InputLabel>
                                    <TextField
                                      fullWidth
                                      size="small"
                                      value={session.overview}
                                      name={`events.${eventIdx}.sessions.${sessionIdx}.overview`}
                                      onChange={formik.handleChange}
                                      error={
                                        (formik?.touched?.events as any)?.[
                                          eventIdx
                                        ]?.sessions?.[sessionIdx]?.overview &&
                                        Boolean(
                                          (formik?.errors?.events as any)?.[
                                            eventIdx
                                          ]?.sessions?.[sessionIdx]?.overview,
                                        )
                                      }
                                      helperText={
                                        (formik?.touched?.events as any)?.[
                                          eventIdx
                                        ]?.sessions?.[sessionIdx]?.overview &&
                                        (formik?.errors?.events as any)?.[
                                          eventIdx
                                        ]?.sessions?.[sessionIdx]?.overview
                                      }
                                      disabled={isEditDisabled}
                                      inputProps={{ maxLength: 1000 }}
                                    />
                                  </Grid>
                                </>
                              )}
                              <Grid
                                item
                                xs={12}
                                display={'flex'}
                                justifyContent={'flex-end'}
                              >
                                <StyledButton
                                  variant="ghost"
                                  size="small"
                                  margin={0}
                                  color="red"
                                  startIcon={MaterialIcons.Delete}
                                  onClick={() =>
                                    handleRemoveSession(eventIdx, sessionIdx)
                                  }
                                  disabled={isEditDisabled}
                                >
                                  Delete Session
                                </StyledButton>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <ConfirmDialog
        open={confirmEventDeletion.open}
        handleClose={() =>
          setConfirmEventDeletion({ open: false, eventId: null })
        }
        onConfirm={() => handleRemoveEventFromDB(confirmEventDeletion.eventId)}
        title="Confirm Deletion"
        disabled={loading}
      >
        Are you sure you want to delete this event? Deleting it will also remove
        all associated sub-sessions.
      </ConfirmDialog>
      <ConfirmDialog
        open={confirmSessionDeletion.open}
        handleClose={() =>
          setConfirmSessionDeletion({ open: false, sessionId: null })
        }
        onConfirm={() =>
          handleRemoveSessionFromDB(confirmSessionDeletion.sessionId)
        }
        title="Confirm Deletion"
        disabled={loading}
      >
        Are you sure you want to delete this session?
      </ConfirmDialog>
    </>
  );
};

export default OnsiteEventConfiguration;
