import { useMutation } from '@tanstack/react-query';
import { ChiefProviderServiceV2 } from '../../../../shared/service/services_v2';

export const useUpdateMeetingStatus = () =>
  useMutation({
    mutationFn: async ({
      meetingId,
      status,
    }: {
      meetingId: string;
      status: string;
    }) => {
      const response = await ChiefProviderServiceV2.updateMeetingStatus(
        meetingId,
        status,
      );
      return response.data;
    },
    meta: {
      errorMessage:
        'An error occurred while attempting to update the meeting status.',
    },
  });
