import { useQuery } from '@tanstack/react-query';
import { TriagingService } from '../../../../shared/service/services_v2';

export const useGetRequestAccessCodes = (requestId: number | null) =>
  useQuery({
    queryKey: ['accessCodes', requestId],
    queryFn: async () => {
      if (!requestId) return null;
      const response = await TriagingService.getAccesscodesOfRequest(requestId);
      return response.data.data;
    },
    enabled: !!requestId,
    meta: {
      errorMessage:
        'An error occurred while attempting to get access Codes of request',
    },
  });
