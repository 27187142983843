import { ApiConfigV2 } from '../../apiConfigV2';
import { NetworkInstanceV2 } from './NetworkInstance';

class Triaging {
  client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  getAccesscodeRequests = (page: any, pageSize: any) => {
    return this.client.get(
      `${ApiConfigV2.api.triaging.getAccesscodeRequests}?page=${page}&pageSize=${pageSize}&requestType=domain_verified`,
    );
  };

  getGeneralRequests = (page: any, pageSize: any, reqType: string) => {
    return this.client.get(
      `${ApiConfigV2.api.triaging.getAccesscodeRequests}?page=${page}&pageSize=${pageSize}&requestType=${reqType}`,
    );
  };

  getAccesscodesOfRequest = (reqId: number) => {
    return this.client.get(
      `${ApiConfigV2.api.triaging.getAccesscodesOfRequest.replace(
        '{{reqId}}',
        reqId.toString(),
      )}`,
    );
  };

  updateRequest = (reqId: any, status: string) => {
    return this.client.post(
      `${ApiConfigV2.api.triaging.getAccesscodeRequests}/${reqId}`,
      {
        status,
      },
    );
  };

  approveDomainRequest = (
    reqId: any,
    status: string,
    accessCode: string | null,
    type: string,
  ) => {
    return this.client.post(
      `${ApiConfigV2.api.triaging.getAccesscodeRequests}/${reqId}`,
      {
        status,
        accessCode,
        type,
      },
    );
  };
}
export { Triaging };
