import { useQuery } from '@tanstack/react-query';
import { OnSiteDeploymentService } from '../../../../shared/service/services_v2';
import { IOnSiteLocations } from '../utils/types/OnsiteDeployment';

export const useGetOnsiteLocations = () =>
  useQuery({
    queryKey: ['onsite-locations'],
    queryFn: () => OnSiteDeploymentService.getOnSiteDeploymentLocations(),
    staleTime: 30 * 60 * 1000, // Data is fresh for 30 minutes
    cacheTime: 40 * 60 * 1000, // Data is kept in cache for 40 minutes
    select: (data) => (data?.data?.data?.locations ?? []) as IOnSiteLocations[],
    meta: {
      errorMessage:
        'An error occurred while attempting to get onsite locations.',
    },
  });
