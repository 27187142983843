import { useMutation } from '@tanstack/react-query';
import { TriagingService } from '../../../../shared/service/services_v2';

export const useUpdateRequests = () =>
  useMutation({
    mutationFn: async ({
      reqId,
      status,
      accessCode,
      type,
    }: {
      reqId: number;
      status: string;
      accessCode: string | null;
      type: string;
    }) => {
      const response = await TriagingService.approveDomainRequest(
        reqId,
        status,
        accessCode,
        type,
      );
      return response.data;
    },
    meta: {
      errorMessage:
        'An error occurred while attempting to update the domain request.',
    },
  });
