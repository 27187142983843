import { Box, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';

import toast from 'react-hot-toast';
import ConfirmDialog from '../../components/confirmDialog/ConfirmDialog';
import { GenericDialog } from '../../components/genericDialog';
import StyledButton from '../../widgets/styledButton/StyledButton';
import { useUpdateRequests } from './hooks/useUpdateRequest';

const AccessCodeDialog = ({
  open,
  handleClose,
  handleSuccess,
  reqId,
  accessCodes,
  refetch,
}: {
  open: boolean;
  handleClose: () => void;
  handleSuccess: () => void;
  reqId: number;
  accessCodes: any[];
  refetch: () => void;
}) => {
  const { mutateAsync, isLoading } = useUpdateRequests();
  const [selectedCode, setSelectedCode] = React.useState<string | null>(null);

  const [showApproveConfirmation, setShowApproveConfirmation] = useState<{
    open: boolean;
    msg: string;
    requestId: number | null;
  }>({ open: false, msg: '', requestId: null });

  const [loading, setLoading] = useState(false);

  const onApproveConfirm = async (requestId: number) => {
    try {
      await mutateAsync({
        reqId: requestId,
        status: 'approved',
        accessCode: selectedCode,
        type: 'DOMAIN_REQUESTS',
      });
      toast.success('Request approved successfully');
      handleSuccess();
      refetch();
      handleClose();
    } catch (err) {
      toast.error('Error occurred while approving the request');
    } finally {
      setLoading(false);
      setShowApproveConfirmation({ open: false, msg: '', requestId: null });
    }
  };

  const handleSubmit = async () => {
    if (!selectedCode) {
      toast.error('Please select an access code.');
      return;
    }
    try {
      setShowApproveConfirmation({
        open: true,
        msg: 'Are you sure you want to approve this request with the selected access code?',
        requestId: reqId,
      });
    } catch (err) {
      toast.error('Error occurred while attempting to update request');
    }
  };

  return (
    <GenericDialog
      noFullScreen
      open={open}
      handleClose={handleClose}
      title={'Select Access Codes'}
      fitToContent
      footerElements={
        <Box display={'flex'} justifyContent={'flex-end'}>
          <StyledButton onClick={handleSubmit}>Submit</StyledButton>
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InputLabel>Access Codes</InputLabel>
          <Select
            value={selectedCode}
            onChange={(e) => setSelectedCode(e.target.value)}
            fullWidth
            displayEmpty
            size="small"
            renderValue={
              selectedCode !== '' ? undefined : () => 'Select an Access Code'
            }
          >
            {accessCodes?.length ? (
              accessCodes.map((item, index) => (
                <MenuItem key={index} value={item.code}>
                  {item.code}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No codes available for this user</MenuItem>
            )}
          </Select>
        </Grid>
      </Grid>
      <ConfirmDialog
        open={showApproveConfirmation.open}
        handleClose={() =>
          setShowApproveConfirmation({ open: false, msg: '', requestId: null })
        }
        onConfirm={() => {
          if (showApproveConfirmation.requestId !== null) {
            onApproveConfirm(showApproveConfirmation.requestId);
          }
        }}
        title="Confirm Approval?"
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Approve"
        disabled={isLoading}
      >
        {showApproveConfirmation.msg ||
          'Are you sure you want to approve this request?'}
      </ConfirmDialog>
    </GenericDialog>
  );
};

export default AccessCodeDialog;
