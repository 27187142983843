import { IconButton, Link, Tooltip, Typography } from '@mui/material';

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { MaterialIcons } from '../../../../../assets/MaterialIcons';
import {
  OrganisationTeamMemberRoleRevised,
  TeamMemberStatusRevised,
} from '../../../../../shared/constants/Organisation';
import { TooltipTypography } from '../../../../components/TooltipTypography/TooltipTypography';
import StatusChip from '../../../../components/StatusChip/StatusChip';

export const ClientMembersGridColumns: ({
  handleEditRow,
  showEdit,
}: {
  handleEditRow: (index: number) => void;
  showEdit: boolean;
}) => GridColDef[] = ({ handleEditRow, showEdit }) => {
  const columns: GridColDef[] = [
    {
      flex: 0.5,
      minWidth: 60,
      field: 'id',
      headerName: 'RefId',
      renderCell: (params: GridRenderCellParams) => {
        return <TooltipTypography title={params.row?.id ?? 'N/A'} />;
      },
    },
    {
      flex: 2,
      minWidth: 150,
      field: 'emailAddress',
      headerName: 'Email',
      renderCell: (params: GridRenderCellParams) => {
        return <TooltipTypography title={params.row?.emailAddress ?? 'N/A'} />;
      },
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'userId',
      headerName: 'User Id',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params?.row?.userId ? (
              <Link
                target="blank"
                href={`/users/${params.row.userId}`}
                color={'secondary'}
                underline="hover"
              >
                <Tooltip title="Click to see more details">
                  <Typography color="secondary.main">
                    {params.row.userId}
                  </Typography>
                </Tooltip>
              </Link>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      flex: 0.5,
      minWidth: 80,
      field: 'organisationTeamId',
      headerName: 'Team Id',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TooltipTypography title={params.row?.organisationTeamId ?? 'N/A'} />
        );
      },
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'organisationTeamName',
      headerName: 'Team Name',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TooltipTypography
            title={params.row?.organisationTeamName ?? 'N/A'}
          />
        );
      },
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'countryName',
      headerName: 'Country',
      renderCell: (params: GridRenderCellParams) => {
        return <TooltipTypography title={params.row?.countryName ?? 'N/A'} />;
      },
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'role',
      headerName: 'Role',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <StatusChip
            bgColor={OrganisationTeamMemberRoleRevised[params.row.role].bgColor}
            color={OrganisationTeamMemberRoleRevised[params.row.role].color}
            title={OrganisationTeamMemberRoleRevised[params.row.role].label}
          />
        );
      },
    },
    {
      flex: 0.5,
      minWidth: 80,
      field: 'status',
      headerName: 'Status',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <StatusChip
            bgColor={TeamMemberStatusRevised?.[params.row.status]?.bgColor}
            color={TeamMemberStatusRevised?.[params.row.status]?.color}
            title={TeamMemberStatusRevised?.[params.row.status]?.label}
          />
        );
      },
    },
  ];

  // Conditionally add the action column based on rowSelection
  if (showEdit) {
    columns.push({
      flex: 0.5,
      minWidth: 50,
      field: 'action',
      headerName: 'Action',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <IconButton onClick={() => handleEditRow(params?.row?.id)}>
            {MaterialIcons.Edit}
          </IconButton>
        );
      },
    });
  }

  return columns;
};
