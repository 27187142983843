import React, { useState } from 'react';
import { Box, Grid, InputLabel, MenuItem, Select } from '@mui/material';

import toast from 'react-hot-toast';
import { GenericDialog } from '../../../../../components/genericDialog';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import ConfirmDialog from '../../../../../components/confirmDialog/ConfirmDialog';
import { useUpdateMeetingStatus } from '../../../hooks/useUpdateMeetingStatus';
import { STATUS_TEXT } from '../../../../../../shared/constants/Provider';

const UpdateMeetingStatusDialog = ({
  open,
  handleClose,
  handleSuccess,
  meetingId,
  currentStatus,
}: {
  open: boolean;
  handleClose: () => void;
  handleSuccess: () => void;
  meetingId: string | null;
  currentStatus: string;
}) => {
  const { mutateAsync, isLoading } = useUpdateMeetingStatus();
  const [selectedStatus, setSelectedStatus] = React.useState<string | null>();

  const [showApproveConfirmation, setShowApproveConfirmation] = useState<{
    open: boolean;
    msg: string;
    meetingId: string;
  }>({ open: false, msg: '', meetingId: '' });

  const handleSubmit = () => {
    if (!selectedStatus) {
      toast.error('Please select a status to update.');
      return;
    }
    if (!!meetingId) {
      setShowApproveConfirmation({
        open: true,
        msg: `Are you sure you want to update the status to ${STATUS_TEXT[selectedStatus]}?`,
        meetingId,
      });
    }
  };

  const onApproveConfirm = async () => {
    try {
      if (meetingId !== null && selectedStatus) {
        await mutateAsync({
          meetingId: showApproveConfirmation.meetingId,
          status: selectedStatus,
        });
        toast.success('Meeting Status updated successfully');
        handleSuccess();
        handleClose();
      } else {
        return;
      }
    } catch (err) {
      toast.error('Error occurred while attempting to update meeting status');
    }
    setShowApproveConfirmation({ open: false, msg: '', meetingId: '' });
  };

  return (
    <GenericDialog
      noFullScreen
      open={open}
      handleClose={handleClose}
      title={'Select Status'}
      fitToContent
      footerElements={
        <Box display={'flex'} justifyContent={'flex-end'}>
          <StyledButton
            onClick={handleSubmit}
            disabled={currentStatus === selectedStatus}
          >
            Submit
          </StyledButton>
        </Box>
      }
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: '150px' }}
      >
        <Grid item xs={2}>
          <InputLabel>Status List</InputLabel>
          <Select
            labelId="status-label"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            label="Meeting Status"
            fullWidth
            displayEmpty
            renderValue={
              selectedStatus !== '' ? undefined : () => 'Select Status'
            }
          >
            <MenuItem value="2">Completed</MenuItem>
            <MenuItem value="8">User No Show</MenuItem>
            <MenuItem value="3">Cancelled</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <ConfirmDialog
        open={showApproveConfirmation.open}
        handleClose={() =>
          setShowApproveConfirmation({ open: false, msg: '', meetingId: '' })
        }
        onConfirm={() => {
          if (
            showApproveConfirmation.meetingId !== null &&
            selectedStatus !== null
          ) {
            onApproveConfirm();
          }
        }}
        title="Confirm Status?"
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Update"
        disabled={isLoading}
      >
        {showApproveConfirmation.msg ||
          `Are you sure you want to update the status to ${selectedStatus}?`}
      </ConfirmDialog>
    </GenericDialog>
  );
};

export default UpdateMeetingStatusDialog;
