import React from 'react';
import { Button, Typography, SxProps, Theme, ButtonProps } from '@mui/material';
import { ArrowLeftIcon, ArrowRightIcon } from '@primer/octicons-react';

const colors = {
  light: '#8c90a6',
  gray: '#707484',
  darkGray: '#3E445B',
  red: '#ec7979',
  green: '#5ebb50',
};

interface StyledButtonProps {
  variant?: 'filled' | 'ghost';
  color?: keyof typeof colors;
  padding?: string | number;
  margin?: string | number;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  [x: string]: unknown;
}

const StyledButton = ({
  sx = [],
  padding = '0.5rem 1rem',
  margin = '1rem',
  children,
  ghostHoverColor = '#FFFFFF',
  variant = 'filled',
  color = 'darkGray',
  ...otherProps
}: Omit<ButtonProps, 'variant' | 'color'> & StyledButtonProps) => (
  <Button
    sx={[
      {
        borderRadius: '0.5rem',
        backgroundColor: variant === 'filled' ? colors[color] : 'transparent',
        color: variant === 'filled' ? '#FFFFFF' : colors[color],
        padding,
        margin,
        textTransform: 'initial',
        transition: 'all 0.2s linear',
        fontWeight: 700,
        minWidth: '1rem',
        '&:hover': {
          backgroundColor:
            variant === 'filled' ? colors[color] : ghostHoverColor,
          filter: variant === 'filled' ? 'brightness(85%)' : ghostHoverColor,
        },
        '&.Mui-disabled': {
          backgroundColor: '#FFFFFF',
          color: 'rgba(0, 0, 0, 0.40)',
          border: variant === 'filled' ? '1px solid #d9d9d9' : 'none',
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...otherProps}
  >
    {children}
  </Button>
);

export default StyledButton;

export const ArrowButton = ({
  direction = 'right',
  children,
  sx = [],
  ...otherProps
}: {
  direction?: 'left' | 'right';
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  [x: string]: unknown;
}) => (
  <StyledButton
    sx={[
      {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...otherProps}
  >
    {direction === 'left' && <ArrowLeftIcon size={24} />}

    <Typography fontWeight="bold" fontSize={16}>
      {children}
    </Typography>

    {direction === 'right' && <ArrowRightIcon size={24} />}
  </StyledButton>
);
