import {
  Autocomplete,
  Divider,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material';
import { FormikProps } from 'formik';
import { useMemo } from 'react';
import { IAddOnsiteDeploymentInputs } from './OnsiteSessionDetails';

import { useGetOnsiteProviders } from '../hooks/useGetOnsiteProviders';

const OnsiteProviderConfiguration = ({
  formik,
  isEditDisabled,
}: {
  formik: FormikProps<IAddOnsiteDeploymentInputs>;
  isEditDisabled: boolean;
}) => {
  const { data: providers } = useGetOnsiteProviders();

  const selectedProvider = useMemo(() => {
    return providers?.find(
      (item) => item?.providerId === +formik?.values?.providerId,
    );
  }, [providers, formik?.values?.providerId]);
  const isDisabled =
    !formik?.values?.fromDate ||
    !formik?.values?.toDate ||
    !formik?.values?.fromTime ||
    !formik?.values?.toTime ||
    isEditDisabled;

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <InputLabel>Setup Provider</InputLabel>
      </Grid>
      <Grid item xs={6}>
        <InputLabel>Provider</InputLabel>
        <Autocomplete
          disablePortal
          key={`providers-${providers?.length}`}
          value={selectedProvider}
          options={providers ?? []}
          getOptionLabel={(option) => `${option?.name} (${option?.providerId})`}
          sx={{ width: '75%' }}
          onChange={(event, newValue) => {
            formik.setFieldValue('providerId', newValue?.providerId);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="ex: John Doe"
              error={
                formik?.touched.providerId && Boolean(formik?.errors.providerId)
              }
              helperText={
                formik?.touched.providerId && formik?.errors.providerId
              }
            />
          )}
          size="small"
          disabled={isDisabled}
        />
      </Grid>
      <Grid item xs={6}>
        <InputLabel>Overview</InputLabel>
        <TextField
          size="small"
          fullWidth
          name="providerOverview"
          value={formik?.values?.providerOverview}
          onChange={formik?.handleChange}
          error={
            formik?.touched?.providerOverview &&
            Boolean(formik?.errors.providerOverview)
          }
          helperText={
            formik?.touched?.providerOverview && formik?.errors.providerOverview
          }
          disabled={isDisabled}
        />
      </Grid>
    </>
  );
};

export default OnsiteProviderConfiguration;
