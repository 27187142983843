import { useQuery } from '@tanstack/react-query';
import { AppServiceV2 } from '../../shared/service/services_v2';
import { LoadingView } from '../../view/components/loadingView';

export const useGetConfig = () =>
  useQuery({
    queryKey: ['config'],
    queryFn: () => AppServiceV2.getConfig(),
    staleTime: 10 * 60 * 1000, // Data is fresh for 10 minutes
    cacheTime: 15 * 60 * 1000, // Data is kept in cache for 15 minutes
    meta: {
      errorMessage: 'An error occurred while attempting to get configs.',
    },
  });

// Temporary HOC for passing configData from react query hooks to class components
export function withConfig(Component: any) {
  return function WrappedComponent(props: any) {
    const { data } = useGetConfig();

    if (!data) {
      return <LoadingView />;
    }

    return <Component configData={data} {...props} />;
  };
}
