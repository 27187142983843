import { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import GeneralRequestView from './GeneralRequestView';
import AccessCodeRequestView from './AccessCodeRequestsView';

const GeneralRequestsViewIndex = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  return (
    <>
      <Tabs
        value={selectedTabIndex}
        onChange={(_event, newValue) => setSelectedTabIndex(newValue)}
        aria-label="General Requests tabs"
        sx={{ mb: 3 }}
      >
        <Tab label="Access Code Requests" value={0} />
        <Tab label="General Requests" value={1} />
      </Tabs>

      {selectedTabIndex === 0 && <AccessCodeRequestView />}
      {selectedTabIndex === 1 && <GeneralRequestView />}
    </>
  );
};

export default GeneralRequestsViewIndex;
