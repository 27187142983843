import { useState } from 'react';
import { Box, TextField } from '@mui/material';
import { GenericDialog } from '../../../components/genericDialog';
import StyledButton from '../../../widgets/styledButton/StyledButton';

export const UserSMSModal = ({ open, handleSend, handleClose }: {
  open: boolean;
  handleSend: (message: string) => void;
  handleClose: () => void
}) => {
  const [message, setMessage] = useState('');
  return <GenericDialog
    open={open}
    handleClose={handleClose}
    noFullScreen
    title='Send SMS'
    extraElements={
      <StyledButton
        disabled={false}
        sx={{ width: '8rem' }}
        onClick={() => {
          handleSend(message);
          setMessage('');
        }}
      >
        SEND
      </StyledButton>
    }
  >
    <Box>
      <TextField
        fullWidth
        onChange={(
          event: React.ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement
          >,
        ) => setMessage(event.target.value)}
        id='sms-message'
        value={message ?? ''}
        placeholder='Add SMS Message'
      />
    </Box>
  </GenericDialog>;
};
