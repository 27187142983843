import { Divider } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { MaterialIcons } from '../../../assets/MaterialIcons';
import Config from '../../../config';
import {
  OnSiteDeploymentService,
  OrganisationServiceV2,
} from '../../../shared/service/services_v2';
import {
  encodeQueryStrings,
  useQueryString,
} from '../../../utilities/hooks/useQueryString';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import TableViewComponent from '../../components/TableViewComponent/TableViewComponent';
import StyledButton from '../../widgets/styledButton/StyledButton';
import { RootViewContext } from '../RootView';
import { OnsiteSessionsColumns } from './utils/grid-columns/OnsiteSessionsColumns';
import { IOnsiteDeployment } from './utils/types/OnsiteDeployment';
import { useGetOrganisations } from '../../../utilities/hooks/useGetOrganisations';

const OnsiteSessionsList = () => {
  const { setAppBreadCrumbs } = useContext(RootViewContext);
  const queryString = useQueryString({
    arrayFormat: 'separator',
    arrayFormatSeparator: ',',
  });
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [onsites, setOnsites] = useState<IOnsiteDeployment[]>([]);
  const [total, setTotal] = useState(0);
  const [organisationMap, setOrganisationMap] = useState<
    Record<string, string>
  >({});
  const [pageSize, setPageSize] = useState<number>(
    queryString?.pageSize ? +queryString?.pageSize : 10,
  );
  const [page, setPage] = useState<number>(
    queryString?.page ? +queryString?.page : 0,
  );

  useEffect(() => {
    const params: Record<string, any> = {
      ...queryString,
      pageSize,
      page,
    };

    history.replace({ search: encodeQueryStrings(params) });
    getOnsiteDeployments(page, pageSize);
  }, [pageSize, page]);

  const { data: organisations } = useGetOrganisations();

  useEffect(() => {
    if (organisations) {
      const orgMap = organisations?.reduce(
        (result: Record<string, string>, org) => {
          result[org.id] = org.name;
          return result;
        },
        {},
      );
      setOrganisationMap(orgMap);
    }
  }, [organisations]);

  useEffect(() => {
    setAppBreadCrumbs([
      {
        label: 'Onsite Deployments',
        type: 'text',
      },
    ]);
  }, []);

  const getOnsiteDeployments = useCallback(
    async (page: number, pageSize: number) => {
      try {
        setLoading(true);
        const response = await OnSiteDeploymentService.getAllDeployments(
          page,
          pageSize,
        );
        if (response && response?.data?.success) {
          console.log('fetched');
          setOnsites(response?.data?.data?.items ?? []);
          setTotal(response?.data?.data?.total ?? 0);
        }
      } catch (error) {
        toast.error('Error occurred while fetching deployments');
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const handleAddNew = () => {
    history.push(Config.paths.onsiteSession.addNew);
  };

  return (
    <>
      <SectionHeader
        title={'Onsite Deployments'}
        subTitle={'Create a new onsite deployment or edit the existing ones'}
        rightElement={
          <StyledButton
            variant="ghost"
            size="large"
            startIcon={MaterialIcons.Add}
            onClick={handleAddNew}
          >
            Add new onsite
          </StyledButton>
        }
      />
      <Divider sx={{ my: 2 }} />
      <TableViewComponent
        columns={OnsiteSessionsColumns(organisationMap)}
        rows={onsites}
        total={total}
        pageSizeCustom={pageSize}
        currentPage={page}
        onPageSizeChange={(pageSize) => {
          setPageSize(pageSize);
        }}
        onPageChange={(page) => {
          setPage(page);
        }}
        paginationMode="server"
        loading={loading}
        onRowClick={(params) =>
          history.push(
            `${Config.paths.onsiteSession.details}/${params?.row?.id}`,
          )
        }
      />
    </>
  );
};

export default OnsiteSessionsList;
