import { Box, CircularProgress } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { MaterialIcons } from '../../../../../../assets/MaterialIcons';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { OrganisationTeamItem } from '../../../../../../shared/types/organisation/OrganisationTeamItem';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import { useGetOrgTeamsWithMembersCount } from '../hooks/useGetOrgTeamsWithMembersCount';
import { OrganisationoDetailContext } from '../OrganisationDetailView';
import AddTeamDialog from './AddTeamDialog';
import TeamList from './TeamList';
import { useQueryClient } from '@tanstack/react-query';

const TeamView = () => {
  const { orgData, refetchOrgTeams, refetchMembersCount, getOrgData } =
    useContext(OrganisationoDetailContext);
  const [currentlyExpandedTeams, setCurrentlyExpandedTeams] = useState<{
    [teamId: number]: boolean;
  }>({});
  const [teams, setTeams] = useState<OrganisationTeamItem[]>([]);
  const [showAddTeamDialog, setShowAddTeamDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const {
    data,
    refetch: refreshTeams,
    isLoading,
    fetchNextPage,
  } = useGetOrgTeamsWithMembersCount(orgData?.id);

  const resetInfiniteScrollTeamsQuery = async () => {
    await queryClient.setQueryData(
      ['org-teams-with-members-count', orgData?.id],
      () => ({
        pages: [],
        pageParams: [1],
      }),
    );
    await refreshTeams();
  };

  const handleRefreshAllTeams = async () => {
    refetchOrgTeams();
    refetchMembersCount();
    resetInfiniteScrollTeamsQuery();
  };

  useEffect(() => {
    setTeams(data?.pages ?? []);
  }, [data]);

  const handleAddTeam = async (name: string) => {
    let response = null;
    try {
      if (!orgData?.id) return;

      setLoading(true);
      response = await OrganisationServiceV2.addTeam(orgData?.id, {
        teamName: name,
      });
    } catch (e) {
      toast.error('An error occurred while attempting to add the new team.');
    } finally {
      setLoading(false);
    }
    if (response && response.data.success) {
      toast.success('Team added successfully!');
      setShowAddTeamDialog(false);
      handleRefreshAllTeams();
    }
  };

  const setTeamAsDefault = async (id: number) => {
    let response = null;
    try {
      if (!orgData?.id) return;

      response = await OrganisationServiceV2.update(orgData.id, {
        name: orgData.name,
        defaultTeamId: id,
      });
    } catch (e) {
      toast.error(
        'An error occurred while attempting to set the team as default.',
      );
    }
    if (response && response.data.success) {
      toast.success('Team set as default.');
      getOrgData();
    }
  };
  const disableTeamAsDefault = async (id: null) => {
    let response = null;
    try {
      if (!orgData?.id) return;

      response = await OrganisationServiceV2.update(orgData.id, {
        name: orgData.name,
        defaultTeamId: null,
      });
    } catch (e) {
      toast.error(
        'An error occurred while attempting to remove the team as default.',
      );
    }
    if (response && response.data.success) {
      toast.success('Team removed as default');
      handleRefreshAllTeams();
    }
  };

  return (
    <>
      {isLoading && <CircularProgress />}
      <Box display="flex" flexDirection="column">
        <Box display={'flex'} justifyContent={'flex-end'}>
          <StyledButton
            size="small"
            variant="ghost"
            color="gray"
            startIcon={MaterialIcons.Add}
            onClick={() => setShowAddTeamDialog(true)}
            sx={{ alignSelf: 'flex-end' }}
            margin={0}
          >
            Add team
          </StyledButton>
        </Box>
        {orgData && (
          <TeamList
            teams={teams}
            loading={isLoading}
            currentlyExpandedTeams={currentlyExpandedTeams}
            setCurrentlyExpandedTeams={setCurrentlyExpandedTeams}
            defaultTeamId={orgData?.defaultTeamId}
            refreshTeams={handleRefreshAllTeams}
            setTeamAsDefault={setTeamAsDefault}
            orgId={orgData?.id}
            disableTeamAsDefault={disableTeamAsDefault}
            fetchMoreTeams={() => fetchNextPage()}
          />
        )}
      </Box>
      <AddTeamDialog
        open={showAddTeamDialog}
        handleClose={() => setShowAddTeamDialog(false)}
        handleSubmit={handleAddTeam}
        loading={loading}
      />
    </>
  );
};

export default TeamView;
