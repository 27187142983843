import { isProduction, isProductionJapan } from '../utilities/App';
import { EnvType } from './constants/App';

const EnvironmentVariablesV2 = {
  [EnvType.Localhost]: {
    baseUrl: 'http://localhost:1700/api/chief',
    talkyUrl: 'http://localhost:5001/api/chief',
    contentUrl: 'http://localhost:5000/api/chief',
    authUrl: 'http://localhost:5003/api/chief',
    providerTeamId: 4,
    insightsDashboard:
      'http://insights.internal.intellect.co.s3-website-ap-southeast-1.amazonaws.com',
    maverickUrl: 'https://maverick.internal.intellect.co',
    unite: 'http://localhost:5002/unite',
  },
  [EnvType.Staging]: {
    baseUrl: 'https://stage.internal.intellect.co/api/chief',
    talkyUrl: 'https://stage.internal.intellect.co/api/chief',
    contentUrl: 'https://stage.internal.intellect.co/api/chief',
    authUrl: 'https://stage.internal.intellect.co/api',
    providerTeamId: 4,
    insightsDashboard:
      'http://insights.internal.intellect.co.s3-website-ap-southeast-1.amazonaws.com',
    maverickUrl: 'https://maverick.internal.intellect.co',
    unite: 'https://stage.internal.intellect.co/unite',
  },
  [EnvType.Production]: {
    baseUrl: 'https://internal.intellect.co/api/chief',
    talkyUrl: 'https://internal.intellect.co/api/chief',
    contentUrl: 'https://internal.intellect.co/api/chief',
    authUrl: 'https://internal.intellect.co/api',
    providerTeamId: 89,
    // insightsDashboard: 'https://wmd.internal.intellect.co',
    insightsDashboard: 'https://insights.intellect.co',
    maverickUrl: 'https://care.intellect.co',
    unite: 'https://internal.intellect.co/unite',
  },
  [EnvType.ProductionJp]: {
    baseUrl: 'https://jp-api.internal.intellect.co/api/chief',
    talkyUrl: 'https://jp-api.internal.intellect.co/api/chief',
    contentUrl: 'https://jp-api.internal.intellect.co/api/chief',
    authUrl: 'https://jp-api.internal.intellect.co/api',
    providerTeamId: 89,
    // insightsDashboard: 'https://wmd.internal.intellect.co',
    insightsDashboard: 'https://insights.intellect.co',
    maverickUrl: 'https://care.intellect.co',
    unite: 'https://jp-api.internal.intellect.co/unite',
  },
};

const EnvironmentV2 =
  EnvironmentVariablesV2[
    isProduction()
      ? EnvType.Production
      : isProductionJapan()
      ? EnvType.ProductionJp
      : EnvType.Staging
  ];

export const ApiConfigV2 = {
  base: {
    admin: EnvironmentV2.baseUrl,
    talky: EnvironmentV2.talkyUrl,
    content: EnvironmentV2.contentUrl,
    insightsDashboard: EnvironmentV2.insightsDashboard,
    maverick: EnvironmentV2.maverickUrl,
    unite: EnvironmentV2.unite,
  },
  api: {
    auth: {
      me: `${EnvironmentV2.authUrl}/user/auth/me`,
      login: `${EnvironmentV2.authUrl}/user/auth/login`,
      deleteRequestList: `${EnvironmentV2.authUrl}/user/auth/delete/list`,
      deleteRequestConfirm: `${EnvironmentV2.authUrl}/user/auth/delete/confirm`,
      getSsoUrl: `${EnvironmentV2.authUrl}/user/sso/verify/email`,
    },
    config: 'dashboard/config',
    fileUpload: 'aws/s3/upload',
    getPresignedURL: 'aws/s3/presigned',
    user: {
      info: 'user',
      resetTotp: 'user/reset-totp',
      forceLogOut: 'user/force-logout',
      sms: 'user/sms',
    },
    catalogue: {
      add: 'content/catalogues/',
      list: 'content/catalogues/',
      detail: 'content/catalogues/{{catalogueId}}',
      delete: 'content/catalogues/{{catalogueId}}',
      courseList: 'content/catalogues/courses/list?page={{pageNo}}',
      sessionList: 'content/catalogues/sessions/list?page={{pageNo}}',
      sessionListRevised: 'content/catalogues/sessions/list',

      // detail: EnvironmentV2.contentUrl + '/content/course/{{courseId}}',
      // add: 'content/course/',
      // update: 'content/course',
      // sessionGroup: {
      //   add: 'content/course/groups/',
      //   // remove: 'dashboard/course/group/',
    },
    collection: {
      add: 'content/collections',
      list: 'content/collections/list',
      detail: 'content/collections/{{collectionId}}',
      // delete: 'content/catalogues/{{catalogueId}}',
      courseList: 'content/catalogues/courses/list',
      sessionList: 'content/catalogues/sessions/list',
      translations: 'content/translations/collection/{{collectionId}}',
      publish: 'content/collections/publish/{{collectionId}}',
      // add: 'content/course/',
      // update: 'content/course',
      // sessionGroup: {
      //   add: 'content/course/groups/',
      //   // remove: 'dashboard/course/group/',
    },
    course: {
      list: 'content/course/',
      detail: `${EnvironmentV2.contentUrl}/content/course/{{courseId}}`,
      add: 'content/course/',
      update: 'content/course',
      publish: 'content/publish/{{courseId}}?scope=course',
      translations: 'content/translations/course/{{courseId}}',
      clone: 'content/{{type}}/{{courseId}}/clone',
      addOrUpdateTranslation: 'content/translations',
      bulkUpdateTranslation: 'content/translations/bulk',
      copyTranslationByBaseLanguage:
        'content/translations/copy/translations/base-language',
      getTopics: 'content/topics/list',
      importTranslations: 'content/translations/upload/session',
      appLocales: 'content/translations/sync/appLocales',
      sessionGroup: {
        add: 'content/course/groups/',
        delete: 'content/course/{{courseId}}/groups/{{groupId}}',
      },
      checkin: {
        add: 'content/sessions/checkin/',
        list: 'content/course/{{courseId}}/checkins/',
        delete: 'content/sessions/{{sessionId}}/checkin/{{checkinId}}/',
      },
      session: {
        add: 'content/course/groups/sessions/',
        details: 'content/sessions/',
        translations: 'content/translations/session/{{sessionId}}',
        update: 'content/course/groups/sessions/',
        publish: 'content/publish/{{sessionId}}?scope=session',
        // open: OldEnvironment.contentUrl + 'app/course/session/open/',
        list: 'content/course/{{courseId}}/groups/sessions',
        addCheckin: 'content/sessions/{{sessionId}}/checkin/',
        updateModuleOrder: 'content/sessions/{{sessionId}}/arrange',
        getTranslationDownloadLink:
          EnvironmentV2.contentUrl + '/content/translations/download/session',
      },
      module: {
        add: 'content/sessions/modules/',
        update: 'content/sessions/modules/',
        updatePageOrder: 'content/sessions/modules/{{moduleId}}/order/page',
        questionnaire: {
          addOrUpdate: 'content/modules/{{moduleId}}/questionnaire',
          updateProperties:
            'content/modules/{{moduleId}}/questionnaire/properties/',
          searchOptionGroupLabels:
            'content/modules/questionnaire/options/groups/search?query={{query}}',
          addOptionGroup: 'content/modules/questionnaire/options/groups/',
          addPersonalityTrait: 'content/modules/questionnaire/traits',
          getTraitsList: 'content/modules/questionnaire/traits',
          getRecommendationsList:
            'content/modules/questionnaire/recommendations',
          addOrEditRecommendation:
            'content/modules/questionnaire/recommendations',
          getRecommendationTranslations:
            'content/translations/recommendations/{{recommendationId}}',
          getTraitDetails: 'content/modules/questionnaire/traits/{{traitId}}',
          getPersonalities: 'content/modules/questionnaire/personalities',
          addOrUpdateTraitScore:
            'content/modules/questionnaire/traits/{{traitId}}/score',
          delete: 'content/modules/{{moduleId}}/questionnaire/{{questionId}}',
        },
        page: {
          add: 'content/sessions/modules/page/content/',
          updateContent: 'content/sessions/modules/page/content/',
          content: 'dashboard/course/session/module/page/content/',
          order: 'dashboard/course/session/module/group/page/order/',
          toggleVisibility:
            'content/sessions/modules/{{moduleId}}/page/{{pageId}}/toggle',
          fetch: 'content/sessions/modules/{{moduleId}}/page/{{pageId}}/',
        },
      },
    },
    organisation: {
      subscription: {
        add: 'billing/organisation/subscription',
        update: 'billing/organisation/subscription',
      },
      creditPool: {
        add: 'billing/organisation/creditPool',
      },
      creditPlan: {
        add: 'billing/organisation/credit',
      },
      creditPlanV2: {
        add: 'billing/planCredit/organisation',
        history: 'billing/planCredit/history/{{planId}}',
      },
      outplacement: {
        add: 'organisations/{{orgId}}/outplacement',
      },
      list: 'organisations',
      detail: 'organisations/{{orgId}}',
      credits: 'organisations/{{orgId}}/credits',
      create: 'organisations',
      update: 'organisations/{{orgId}}',
      updateStatus: 'organisations/status/{{orgId}}',
      members: 'organisations/{{orgId}}/members',
      membersCount: 'organisations/{{orgId}}/members-count',
      membersInMultipleTeams:
        'organisations/{{orgId}}/members/in-multiple-teams',
      domains: {
        getDomainEntityConfigs: 'organisations/{{orgId}}/sso-entity-configs',
        getDomains: 'organisations/{{orgId}}/sso-domains',
        createDomain: 'organisations/{{configId}}/sso-domains',
        updateDomain: 'organisations/{{domainId}}/sso-domains',
      },
      mailChimpSync: '/organisations/sync/mailchimp/{{orgId}}',
      countries: {
        list: '/organisations/countries/list',
      },
      config: {
        team: {
          getList: 'config/list/team/{{teamId}}',
          add: 'config/create/team/{{teamId}}',
        },
        org: {
          getList: 'config/list/org/{{orgId}}',
          add: 'config/create/org/{{orgId}}',
          delete: 'config/delete/org/{{orgId}}/{{configId}}',
          mfa: 'organisations/mfa/list',
          mfaEnabled: 'organisations/mfa/{{orgId}}',
          primaryMfaFactor: 'organisations/mfa/{{orgId}}/factor/{{Id}}',
        },
        update: 'config/{{configId}}',
        delete: 'config/{{configId}}',
      },
      team: {
        create: 'organisations/{{id}}/team/',
        update: 'organisations/team/{{id}}',
        addMembers: 'organisations/team/{{id}}/member/',
        addChildTeam: 'organisations/team/{{id}}/child-team',
        addMultipleMembers: 'organisations/team/{{id}}/member/v2',
        updateMember: 'organisations/team/member/{{id}}',
        deleteMultipleMembers: '/organisations/team/member/id',
        deleteMultipleMemberEmails: '/organisations/team/member/email',
        bulkOffboardMembers: '/organisations/upload/offboard',
        list: 'organisations/{{id}}/team', // Not Paginated
        listWithMembersCount: 'organisations/{{id}}/team-with-members-count', // Paginated
        members: 'organisations/team/{{teamId}}/member',
        bulkAddMembers: 'organisations/bulk/member/{{id}}',
      },
      accessCodes: 'organisations/{{id}}/codes',
      upload: 'organisations/{{id}}/import',
      memberActivate: 'organisations/{{id}}/members/activate',
      memberActivateSelected: 'organisations/{{id}}/members/activate-selected',
      membersStatusToPending: 'organisations/{{id}}/members/status/pending',
      bulkUpdateMembersTeam: 'organisations/{{id}}/members/update/team',
      sendReport: 'report/processReport',
      domainUsers: 'organisations/{{id}}/domain/users',
      accessCodeUsers: 'organisations/{{id}}/accesscode/users',
      pecWorkflow: {
        getOutplacementOrgs: 'organisations/pec-workflow/outplacement-orgs',
        getExistingMemberStatus: 'organisations/pec-workflow/status',
        runPecWorkflowMigration: 'organisations/pec-workflow/migrate-member',
      },
      exportMembers:
        EnvironmentV2.baseUrl + '/organisations/download/members/org/{{orgId}}',
    },
    billing: {
      savePlan: '/billing/plan/',
      addAssociatedPool: 'billing/plan/{{planId}}/associatedPool',
      user: {
        credit: {
          request: 'billing/user/credit/request',
          refund: 'app/billing/credit/refund/{{userId}}',
        },
        history: '/billing/planCredit/user/history/{{userId}}',
      },
    },
    marketing: {
      signupList: 'marketing/signups',
      campaigns: 'marketing/campaigns',
      codes: 'marketing/access/code',
      updateCode: 'marketing/access/code/{{code}}',
    },
    emailTemplates: {
      create: 'email-template',
      getList: 'email-template',
      getOne: 'email-template/{{templateId}}',
      getEmails: 'emails',
      getEmail: 'emails/{{id}}',
      getTemplates: 'emails/templates',
      getTemplate: 'emails/templates/{{id}}',
      getOverrideList: 'emails/overrides',
      getOverride: 'emails/overrides/{{id}}',
    },
    reward: {
      add: 'dashboard/rewards/',
      list: 'dashboard/rewards/',
      detail: 'dashboard/rewards/{{id}}',
      update: 'dashboard/rewards/{{id}}',
      delete: 'dashboard/rewards/{{id}}',
    },
    provider: {
      listV1: 'provider',
      listV2: 'v2/provider',
      profileV2: 'provider/dashboard/{{providerId}}',
      getSlotsV2: 'provider/slots/{{providerId}}',
      getClientsV2: 'provider/clients/{{providerId}}',
      getExpertiseV2: 'provider/expertise/{{providerId}}',
      getLanguageV2: 'provider/languages/{{providerId}}',
      getHolidayV2: 'provider/holidays/{{providerId}}',
      getAttributesV2: 'provider/attributes/{{providerId}}',
      sessionsV2: 'provider/{{providerId}}/sessions',
      getOrgMapped: 'provider/organisations/{{orgId}}',
      addOrgProviders: 'provider/organisations/{{orgId}}/{{providerId}}',
      updateV2: 'provider/profile',
      list: 'provider/dashboard',
      country: 'provider/dashboard/countries/list',
      city: 'provider/dashboard/location/city',
      cities: 'provider/dashboard/countries/{{countryId}}/cities/list',
      location: 'provider/dashboard/{{providerId}}/location',
      addLocation: 'provider/dashboard/location/provider',
      coach: {
        manualAssign: 'provider/dashboard/{{providerId}}/client/{{userId}}',
      },
      update: 'provider/dashboard/{{providerId}}',
      service: 'provider/dashboard/can-match/{{providerId}}',
      sessions: 'provider/dashboard/{{providerId}}/sessions',
      updateSlots: 'provider/dashboard/{{providerId}}/slots',
      saveSlots: 'provider/dashboard/slots/{{providerId}}',
      saveOverrides: 'provider/dashboard/dailyoverride/{{providerId}}',
      saveBlocks: 'provider/dashboard/dailyoverride/block/{{providerId}}',
      saveLeaves: 'provider/dashboard/leaves',
      removeLeaves: 'provider/dashboard/delete/leaves',
      removeLeavesV2: 'v2/provider/dashboard/delete/leaves',
      getSlots: 'provider/dashboard/slots/{{providerId}}',
      attributes: 'provider/dashboard/attributes/{{providerId}}',
      languages: 'provider/dashboard/languages/{{providerId}}',
      saveRanking: 'provider/dashboard/rank/{{providerId}}',
      getRanking: 'provider/dashboard/expertise/{{providerId}}',
      meeting: {
        details: 'provider/dashboard/meetings/{{meetingId}}',
        history: 'provider/meetings/info/{{meetingId}}',
        reschedule: 'provider/dashboard/{{providerId}}/meeting/{{meetingId}}',
        cancel: 'provider/dashboard/{{providerId}}/meeting/{{meetingId}}',
        statusUpdate: 'provider/meetings/{{meetingId}}',
      },
      user: {
        list: 'provider/dashboard/users/{{userId}}/list',
      },
      holiday: {
        list: 'provider/dashboard/{{providerId}}/holiday',
        delete: 'provider/dashboard/{{providerId}}/holiday/{{id}}',
      },
      client: {
        list: 'provider/dashboard/{{providerId}}/client',
        delete: 'provider/dashboard/{{providerId}}/client/{{userId}}',
      },
      profile: 'provider/dashboard/profile',
      inPerson: {
        locationList: 'provider/dashboard/clinics/list',
        locationDetails: 'provider/dashboard/clinics/{{clinicId}}',
        addLocation: 'provider/dashboard/clinics',
        updateLocation: 'provider/dashboard/clinics/{{clinicId}}',
        listRooms: 'provider/dashboard/clinics/{{clinicId}}/rooms',
        addRoom: 'provider/dashboard/clinics/{{clinicId}}/rooms',
        roomDetails: 'provider/dashboard/rooms/{{roomId}}',
        roomEvents: 'provider/dashboard/rooms/{{roomId}}/events',
        updateRoom: 'provider/dashboard/rooms/{{roomId}}',
        saveProviderPreferredLocations:
          'provider/dashboard/{{providerId}}/preferred-clinics',
        getProviderPreferredLocations:
          'provider/dashboard/{{providerId}}/preferred-clinics',
        deleteProviderPreferredLocations:
          'provider/dashboard/{{providerId}}/preferred-clinics',
      },
      userMeetings: 'provider/dashboard/users/meetings',
      availability: 'operations/availability',
      meetings: 'operations/meetings',
      caseNotes: 'provider/casenotes',
    },
    auditlog: {
      getAuditLogs: 'audit-log/{{refId}}',
      getOrganisationAuditLogs: 'audit-log/organisation/{{refId}}',
      getProviderAuditLogs: 'audit-log/provider/{{refId}}',
      getSessionAuditLogs: 'audit-log/content/session/{{refId}}',
      getCoourseAuditLogs: 'audit-log/content/course/{{refId}}',
      getPublishedAuditLogs: 'audit-log/content/publish/{{refId}}',
      getGeneralRequestsAuditLogs: 'audit-log/triaging/general',
      getAccessCodeRequestsAuditLogs: 'audit-log/triaging/accessCode',
      getDomainRequestsAuditLogs: 'audit-log/triaging/domain',
    },
    userManagement: {
      getUsers: 'user-management/users',
      addUser: 'user-management/user',
      editUser: 'user-management/user/{{userId}}',
      getRoles: 'user-management/roles',
      searchUser: 'user-management/search-user',
    },
    onSiteDeployment: {
      getOnSiteDeploymentList: 'onsite',
      createOnSiteDeployment: 'onsite',
      getOnSiteDeploymentDetails: 'onsite/{{onSiteId}}',
      getOnSiteDeploymentMeetings: 'onsite/{{onSiteId}}/meetings',
      updateOnSiteDeploymentDetails: 'onsite/{{onSiteId}}',
      updateOnSiteDeploymentStatus: 'onsite/status/{{onSiteId}}',
      deleteOnSiteDeploymentEvent: 'onsite/event/{{eventId}}',
      deleteOnSiteDeploymentSession: 'onsite/session/{{sessionId}}',
      getOnSiteLocations: 'onsite/locations/list',
      getOnSiteProviders: 'onsite/providers/list',
    },
    analytics: {
      metabaseQuestion: '/dashboard/metabase/{{questionId}}',
    },
    triaging: {
      getAccesscodeRequests: '/triaging/requests',
      send_email: '/triaging/send-email',
      getAccesscodesOfRequest: '/triaging/requests/{{reqId}}/access_code',
    },
    hrisSync: '/hris/sync_hris_data',
  },
  values: {
    providerTeamId: EnvironmentV2.providerTeamId,
  },
};
