import { ApiConfigV2 } from '../../apiConfigV2';
import { AppConfig } from '../../types/common/App';
import { NetworkInstanceV2 } from './NetworkInstance';

class App {
  client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  getConfigV1 = () => this.client.get(ApiConfigV2.api.config);

  getConfig = (): Promise<AppConfig> =>
    this.client
      .get(ApiConfigV2.api.config)
      .then((response) => response.data.data);

  uploadImage = (files: File[]) => {
    const formData = new FormData();

    files.forEach((file: File) => {
      formData.append('files', file);
    });

    return this.client.post(ApiConfigV2.api.fileUpload, formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });
  };

  getFilePresignedURL = (data: { fileName: string; fileType: string }) => {
    return this.client.post(ApiConfigV2.api.getPresignedURL, data);
  };

  sendSMS = (body: { userId: number[], message: string }) => {
    return this.client.post(ApiConfigV2.api.user.sms, body);
  };
}

export { App };
