import { useQuery } from '@tanstack/react-query';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { OrganisationTeamItem } from '../../../../../../shared/types/organisation/OrganisationTeamItem';

export const useGetOrgTeams = (orgId?: number) =>
  useQuery({
    enabled: Boolean(orgId),
    queryKey: ['org-teams', orgId],
    queryFn: () => {
      if (orgId) {
        return OrganisationServiceV2.getTeams(orgId);
      }
    },
    staleTime: 5 * 60 * 1000, // Data is fresh for 5 minutes
    cacheTime: 10 * 60 * 1000, // Data is kept in cache for 10 minutes
    select: (data) => (data?.data?.data ?? []) as OrganisationTeamItem[],
    meta: {
      errorMessage: 'An error occurred while attempting to get org teams',
    },
  });
