import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { TooltipTypography } from '../../../components/TooltipTypography/TooltipTypography';
import StatusChip from '../../../components/StatusChip/StatusChip';
import moment from 'moment/moment';
import { CaseNotesMeetingStatusMapping } from '../../../../shared/constants/Provider';

export const OnSiteMeetingColumns: () => GridColDef[] = () => [
  {
    flex: 1,
    minWidth: 100,
    field: 'userId',
    headerName: 'ID',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params.row?.userId} />;
    },
  },
  {
    flex: 1,
    minWidth: 100,
    field: 'userName',
    valueGetter: (params: GridRenderCellParams) => params?.row?.profile?.name,
    headerName: 'Name',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params?.row?.userName} />;
    },
  },
  {
    flex: 1,
    minWidth: 80,
    field: 'meetingId',
    headerName: 'MeetingId',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params?.row?.meetingId} />;
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'meetingTime',
    headerName: 'Date/Time(UTC)',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <TooltipTypography
          title={moment(params.row?.meetingTime * 1000).format('DD MMM, HH:mm')}
        />
      );
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'meetingNotes',
    headerName: 'Notes',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <TooltipTypography
          title={params.row?.meetingNotes.join(', ')}
        />
      );
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'meetingStatus',
    headerName: 'Status',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <StatusChip
          bgColor={
            params?.row?.meetingStatus === '0'
              ? 'var(--chip-green-bg-color)'
              : 'var(--chip-grey-bg-color)'
          }
          color={
            params?.row?.meetingStatus === '0'
              ? 'var(--chip-green-color)'
              : 'var(--chip-grey-color)'
          }
          title={CaseNotesMeetingStatusMapping?.[params?.row?.meetingStatus] ?? ''}
        />
      );
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'userPhone',
    headerName: 'Phone',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <TooltipTypography
          title={params.row?.userPhone}
        />
      );
    },
  },
];
