import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  OnSiteDeploymentEventsMapping,
  OnSiteStatusLabelColors,
  OnSiteTypesMapping,
} from '../../../../../shared/constants/OnsiteDeployment';
import StatusChip from '../../../../components/StatusChip/StatusChip';
import { TooltipTypography } from '../../../../components/TooltipTypography/TooltipTypography';
import {
  getDisplayDate,
  getDisplayDateTime,
} from '../../../../../utilities/Display';
import { OrganisationListItem } from '../../../../../shared/types/organisation/OrganisationListItem';

export const OnsiteSessionsColumns: (
  organisationMap: Record<string, string>,
) => GridColDef[] = (organisationMap) => [
  {
    flex: 0.5,
    minWidth: 60,
    field: 'organisationId',
    headerName: 'Organisation',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <>
          <TooltipTypography
            title={organisationMap?.[params.row?.organisationId] ?? 'N/A'}
          />
        </>
      );
    },
  },
  {
    flex: 0.5,
    minWidth: 80,
    field: 'onSiteType',
    headerName: 'Type of deployment',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <TooltipTypography
          title={
            params.row?.onSiteType
              ? OnSiteTypesMapping[params?.row?.onSiteType as string]
              : 'N/A'
          }
        />
      );
    },
  },
  {
    flex: 0.5,
    minWidth: 120,
    field: 'opsSite',
    headerName: 'Ops Site',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <TooltipTypography title={params.row?.location?.address ?? 'N/A'} />
      );
    },
  },
  {
    flex: 0.5,
    minWidth: 120,
    field: 'date',
    headerName: 'Date',
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <TooltipTypography
          title={`${params.row?.fromDate ?? '-'} / ${
            params?.row?.toDate ?? '-'
          }`}
        />
      );
    },
  },
  {
    flex: 0.5,
    minWidth: 80,
    field: 'providerId',
    headerName: 'Sourced Clinician',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params?.row?.provider?.name ?? 'N/A'} />;
    },
  },
  {
    flex: 1,
    minWidth: 80,
    field: 'sessions',
    headerName: 'Type of Events',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <TooltipTypography
          title={
            params?.row?.events
              ?.map(
                (event: { eventType: string }) =>
                  OnSiteDeploymentEventsMapping?.[event?.eventType] ?? null,
              )
              ?.filter(Boolean)
              ?.join(', ') ?? '-'
          }
        />
      );
    },
  },
  {
    flex: 1,
    minWidth: 80,
    field: 'updatedAt',
    headerName: 'Last Updated On',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <TooltipTypography title={getDisplayDateTime(params?.row?.updatedAt)} />
      );
    },
  },
  {
    flex: 0.5,
    minWidth: 80,
    field: 'status',
    headerName: 'Status',
    renderCell: (params: GridRenderCellParams) => {
      const status: keyof typeof OnSiteStatusLabelColors =
        params.row?.status ?? 0;

      return (
        <StatusChip
          bgColor={OnSiteStatusLabelColors[status].bgColor}
          color={OnSiteStatusLabelColors[status].color}
          title={OnSiteStatusLabelColors[status]?.title ?? 'N/A'}
        />
      );
    },
  },
];
