import { OrganisationV2 } from './Organisation';

import { NetworkInstanceV2 } from './NetworkInstance';
import { ApiConfigV2 } from '../../apiConfigV2';

const UniteConfig = {
  baseURL: ApiConfigV2.base.unite,
};

export class UniteV2 {
  client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  syncHRISData = (serviceAccountId: string) =>
    this.client.post(
      `${ApiConfigV2.api.hrisSync}`,
      { serviceAccountId },
      UniteConfig,
    );
}
